import React from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { Button } from "@mui/material";
import Textfield from "../../components/FormUI/Textfield";
import Selectfield from "../../components/FormUI/Selectfield";

import styles from "./ExtraInfoForm.module.css";

const ExtraInfoForm = (props) => {
  const { updateApp, extraInfo } = props;

  const handleSubmit = async (values, { resetForm }) => {
    await updateApp(values);

    // notifyUser("The extra information has been saved successfully!");
    resetForm();
  };

  const initialValues = {
    ref1Name: "",
    ref1Relationship: "",
    ref1Occupation: "",
    ref1Address: "",
    ref1Email: "",
    ref1Mobile: "",
    ref2Name: "",
    ref2Relationship: "",
    ref2Occupation: "",
    ref2Address: "",
    ref2Email: "",
    ref2Mobile: "",
    repaymentMode: "",
  };

  const validationSchema = Yup.object().shape({
    ref1Name: Yup.string()
      .required("The name of a referee is required!")
      .max(100),
    ref1Relationship: Yup.string()
      .required("The relationship is required!")
      .max(80),
    ref1Occupation: Yup.string()
      .required("The occupation is required!")
      .max(80),
    ref1Address: Yup.string().required("The address is required!").max(150),
    ref1Email: Yup.string()
      .email("A valid email is required!")
      .max(80)
      .required("An email address is required!"),
    ref1Mobile: Yup.string().required("The mobile number is required!").max(20),
    ref2Name: Yup.string()
      .required("The name of a referee is required!")
      .max(100),
    ref2Relationship: Yup.string()
      .required("The relationship is required!")
      .max(80),
    ref2Occupation: Yup.string()
      .required("The occupation is required!")
      .max(80),
    ref2Address: Yup.string().required("The address is required!").max(150),
    ref2Email: Yup.string()
      .email("A valid email is required!")
      .max(80)
      .required("An email address is required!"),
    ref2Mobile: Yup.string().required("The mobile number is required!").max(20),
    repaymentMode: Yup.string().required(
      "The loan repayment mode is required!"
    ),
    // altEmail: Yup.string()
    //   .email("A valid email is required!")
    //   .max(80)
    //   .required("An email address is required!"),
    // altPhone: Yup.string().required("A mobile number is required!").max(20),
  });

  const repaymentModeOptions = {
    "Salary Source": "Salary Source",
    "Bank Authority": "Bank Authority",
  };

  return (
    <section className={styles.form}>
      <Formik
        initialValues={extraInfo || initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form>
          <h3 className={styles.sectionTitle}>Other Information</h3>

          {/* repayment mode */}
          <div className={styles.controls}>
            <div className={styles.row}>
              <Selectfield
                label="Repayment Mode*"
                name="repaymentMode"
                options={repaymentModeOptions}
              />
            </div>
          </div>

          <hr />
          <h3 className={styles.sectionTitle}>Referees</h3>

          {/* referees ---------------------------------------*/}

          {/* ref 1 */}
          <p className={styles.paraTitle}>
            Has to be a relative, if you are married, has to be your spouse
          </p>
          <div className={styles.controls}>
            <div className={styles.row}>
              <Textfield name="ref1Name" label="Name" />
              <Textfield name="ref1Relationship" label="Relationship" />
            </div>
            <div className={styles.row}>
              <Textfield name="ref1Occupation" label="Occupation" />
              <Textfield name="ref1Address" label="Address" />
            </div>
            <div className={styles.row}>
              <Textfield name="ref1Email" label="Email" />
              <Textfield name="ref1Mobile" label="Mobile" />
            </div>
          </div>

          {/* ref 2 */}
          <p className={styles.paraTitle}>
            Has to be a workmate (Manager / Supervisor)
          </p>
          <div className={styles.controls}>
            <div className={styles.row}>
              <Textfield name="ref2Name" label="Name" />
              <Textfield name="ref2Relationship" label="Relationship" />
            </div>
            <div className={styles.row}>
              <Textfield name="ref2Occupation" label="Occupation" />
              <Textfield name="ref2Address" label="Address" />
            </div>
            <div className={styles.row}>
              <Textfield name="ref2Email" label="Email" />
              <Textfield name="ref2Mobile" label="Mobile" />
            </div>
          </div>

          {/* actions */}
          <div className={styles.actions}>
            <Button
              className={styles.actionBtn}
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </section>
  );
};

export default ExtraInfoForm;
