import { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import LoanDetails from "./LoanDetails";
import Expenses from "./Expenses";
import Actions from "./Actions";
import { Link, useParams } from "react-router-dom";
import Error from "../error/Error";
import { format } from "date-fns";
import { useSendMail } from "../../hooks/useSendMail";
import { useAuthContext } from "../../hooks/useAuthContext";

import useGetDoc from "../../hooks/useGetDoc";
import useGetDocs from "../../hooks/useGetDocs";

import styles from "./PreAssessment.module.css";
import { SettingsInputHdmiSharp } from "@mui/icons-material";

const EMAIL_SUBJECT = "Unsecured Personal Loan Application Update";

const PreAssessment = () => {
  const [repayAmt, setRepayAmt] = useState("");
  const { id } = useParams();
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [appStatus, setAppStatus] = useState(null);
  const [isRecommended, setIsRecommended] = useState(false);
  const { sendMsg } = useSendMail();
  const { user } = useAuthContext();
  const [isEscalatedTo, setIsEscalatedTo] = useState(false);

  // get the UPL application based on the id
  const {
    data,
    error: uplAppsError,
    isLoading: uplAppsIsLoading,
  } = useGetDoc("unsecuredLoanApplications", id);

  // get the roles
  const { data: roles } = useGetDoc("users", user.uid);

  // get the UPL assessment based on the id
  const {
    data: assessmentData,
    error: assmntError,
    isLoading: assmntIsLoading,
  } = useGetDoc("assessedUPL", id);

  // get the reference data i.e. i rate, est fee rate, insurance rate, ccemail
  const { data: rates } = useGetDocs("refs");

  const [finalExpenses, setFinalExpenses] = useState({
    rent: 0,
    electricity: 0,
    water: 0,
    mobile: 0,
    groceries: 0,
    schoolFees: 0,
    travel: 0,
    subscriptions: 0,
    entertainment: 0,
    insurance: 0,
    homeLoans: 0,
    otherLoans: 0,
    hirePurchase: 0,
    otherExpenses: 0,
    creditCards: 0,
  });
  const [result, setResult] = useState({
    totalOutgoing: 0,
    surplus: 0,
    newRepayment: 0,
    surplusAvailable: 0,
    netWeeklySurplus: 0,
    dti: 0,
    netMonthly: 0,
    additionalIncome: 0,
    totalNetMonthly: 0,
  });
  const [loanDeets, setLoanDeets] = useState({
    loanAmount: 0,
    loanTerm: 0,
    monthlyInstallment: 0,
    estFee: 0,
    insurance: 0,
    totalAmountFinanced: 0,
    totalInterest: 0,
    totalRepayable: 0,
  });
  const [finalRates, setFinalRates] = useState({
    interestRate: 0,
    insuranceRate: 0,
    estFeeRate: 0,
    surchargeRate: 0,
  });

  const [surcharge, setSurcharge] = useState({
    surcharge: 0,
    surchargeRate: 0,
  });

  useEffect(() => {
    let addIncome = 0;
    if (data !== null) {
      if (data.status === "Recommended") {
        setIsRecommended(true);
      }

      if (data.escalatedTo && data.escalatedTo.trim !== "") {
        setIsEscalatedTo(true);
      }
    }

    if (assessmentData !== null) {
      if (assessmentData.surchargeRate > 0) {
        setSurcharge((prevState) => ({
          ...prevState,
          surcharge: assessmentData.surcharge,
        }));
      }
    }
  }, [data, assessmentData]);

  useEffect(() => {
    if (rates !== null) {
      setFinalRates(() => ({
        interestRate: rates[0].interestRate,
        insuranceRate: rates[0].insuranceRate,
        estFeeRate: rates[0].estFeeRate,
      }));
    }
  }, [rates]);

  // const sendMessage = async (message, subject = null, escalatedTo = null) => {
  const sendMessage = async (message, escalatedTo = null) => {
    setIsSending(true);

    //set the To email
    let emailTo = data.email; // set the applicant email
    if (escalatedTo) {
      if (escalatedTo.trim() !== "") {
        emailTo = escalatedTo;
      }
    }

    // send the message
    try {
      const response = await sendMsg(
        emailTo,
        EMAIL_SUBJECT,
        message,
        message,
        user.email
      );

      setIsSending(false);
    } catch (err) {
      setError(err.message);
      setIsSending(false);
    }
  };

  return (
    <Container className={styles.page}>
      {isLoading && <p>Loading data...</p>}
      {/* {data && rates ? ( */}
      {data && rates && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* ========================================================================================*/}
            {/* Actions section */}
            <Actions
              sendMsg={sendMessage}
              isSending={isSending}
              loanAmount={data.loanAmount}
              firstName={data.firstName}
              lastName={data.lastName}
              otherNames={data.otherNames}
              dateApplied={data.dateApplied}
              appID={id}
              status={data.status}
              result={result}
              loanDeets={loanDeets}
              finalExpenses={finalExpenses}
              finalRates={finalRates}
              appStatus={appStatus}
              setAppStatus={setAppStatus}
              surcharge={surcharge}
              roles={roles}
              isEscalatedTo={isEscalatedTo}
              annualSalary={data.annualSalary}
              additionalIncome={data.additionalIncome}
            />
          </Grid>

          {/* ========================================================================================*/}
          {/* Display main loan details */}

          <Grid item xs={12}>
            <Typography variant="p">
              <Link to={`/upl-application/${id}`} target="_blank">
                {data.firstName} {data.lastName} || ${data.loanAmount}
              </Link>
            </Typography>
            <br></br>
            <Typography variant="p">{data.email}</Typography>
            <br></br>
            {data.dateApplied && (
              <Typography variant="p">
                <strong>Application Date: </strong>
                {format(data.dateApplied.toDate(), "EEE dd/MM/yyyy hh:mm a")}
                <br></br>
              </Typography>
            )}

            {isRecommended && (
              <Typography variant="p">
                <strong>Recommendation Date:</strong>{" "}
                {data.dateRecommended &&
                  format(
                    data.dateRecommended.toDate(),
                    "EEE dd/MM/yyyy hh:mm a"
                  )}
                <br></br>
                <span style={{ textAlign: "left" }}>
                  <strong>Recommendation:</strong> <br></br>
                  {data.recommendation && data.recommendation}
                </span>
              </Typography>
            )}
          </Grid>

          {/* ========================================================================================*/}
          {/* Loan details & Expenses*/}

          {/* Loan details */}
          <Grid item xs={12} sm={4}>
            {/* {data && assessmentData && ( */}
            <LoanDetails
              setRepayAmt={setRepayAmt}
              data={data}
              setLoanDeets={setLoanDeets}
              setFinalRates={setFinalRates}
              finalRates={finalRates}
              fRates={rates}
              assessmentData={assessmentData}
              surcharge={surcharge}
              setSurcharge={setSurcharge}
            />
            {/* )} */}
          </Grid>

          {/* Expenses */}
          <Grid item xs={12} sm={8}>
            {/* {data && assessmentData && ( */}
            {data && (
              <Expenses
                repayAmt={isNaN(repayAmt) ? 0 : repayAmt}
                data={data}
                setResult={setResult}
                setFinalExpenses={setFinalExpenses}
                assessmentData={assessmentData}
              />
            )}
          </Grid>
        </Grid>
        // ) : null}
      )}
      {/* ========================================================================================*/}

      {error && <Error msg={error} module="Pre-assessment" />}
      {uplAppsError && <Error msg={uplAppsError} module="Pre-assessment" />}
      {assmntError && <Error msg={assmntError} module="Pre-assessment" />}
    </Container>
  );
};

export default PreAssessment;
