import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import Filter from "./Filter";

const columns = [
  { id: "i", label: "#", minWidth: 10, align: "left" },
  { id: "firsName", label: "Name", minWidth: 100, align: "left" },
  {
    id: "appliedAt",
    label: "Date Applied",
    minWidth: 100,
    align: "left",
    // format: (value) => value.toLocaleString("en-US"),
  },
  { id: "applicationStatus", label: "Status", minWidth: 50, align: "center" },
  {
    id: "loanAmount",
    label: "Amount",
    minWidth: 70,
    align: "right",
  },
  {
    id: "assessor",
    label: "Assessor",
    minWidth: 70,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 70,
    align: "center",
  },
];

const ListApplications = (props) => {
  const { apps, currentFilter } = props;

  return (
    // <div style={{ width: "95%", margin: "0 auto" }}>
    <Paper
      sx={{ width: "95%", margin: "1rem auto", padding: "1rem" }}
      elevation={5}
    >
      {/* <Filter /> */}
      {apps && apps.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TableContainer
            margin="1rem"
            sx={{ padding: ".5rem", width: "95%", margin: "0 auto" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "#00adbb",
                        color: "#fff",
                      }}
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        align: column.align,
                        fontSize: "0.8rem",
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {rowsAfterPagingAndSorting().map((app) => ( */}
                {apps.map((app, i) => (
                  <TableRow key={app.id} style={{ fontSize: "0.8rem" }}>
                    {/* count */}
                    <TableCell
                      style={{ fontSize: "0.8rem", textAlign: "left" }}
                    >
                      {i + 1}
                    </TableCell>

                    {/* customer name */}
                    <TableCell
                      style={{ fontSize: "0.8rem", textAlign: "left" }}
                    >
                      {app.docStatus && (
                        <span>
                          <Tooltip title="Closing documents produced" arrow>
                            <DescriptionOutlinedIcon
                              fontSize="medium"
                              color="success"
                              style={{ verticalAlign: "bottom" }}
                            />
                          </Tooltip>
                        </span>
                      )}
                      <Link to={`/upl-application/${app.id}`} target="_blank">
                        {app.firstName} {app.otherNames} {app.lastName}
                      </Link>
                    </TableCell>

                    {/* date applied */}
                    <TableCell
                      style={{ fontSize: "0.8rem", textAlign: "left" }}
                    >
                      {app.appliedAt
                        ? format(
                            new Date(app.appliedAt.seconds * 1000),
                            "EEE dd MMM yyyy hh:mm a"
                          )
                        : null}
                    </TableCell>

                    {/* application status */}
                    <TableCell
                      style={{ fontSize: "0.8rem", textAlign: "center" }}
                    >
                      {app.status}
                    </TableCell>

                    {/* loan amount */}
                    <TableCell
                      style={{ fontSize: "0.8rem", textAlign: "right" }}
                    >
                      ${new Intl.NumberFormat().format(app.loanAmount)}
                    </TableCell>

                    {/* assessor name */}
                    <TableCell
                      style={{ fontSize: "0.8rem", textAlign: "left" }}
                    >
                      {/* {app.assessor
                        ? app.assessor.displayName.substring(
                            0,
                            app.assessor.displayName.indexOf(" ")
                          )
                        : null} */}

                      {app.assessor &&
                        app.assessor.displayName.substring(
                          0,
                          app.assessor.displayName.indexOf(" ")
                        )}
                    </TableCell>

                    {/* action */}
                    <TableCell
                      style={{ fontSize: "0.8rem", textAlign: "center" }}
                    >
                      <Link to={`/assess/${app.id}`} target="_blank">
                        Assess
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[pageSize]}
            // rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </div>
      ) : (
        <p>
          {/* Great Job!!! There are no more applications in the {currentFilter}{" "}
          stage. */}
        </p>
      )}

      {/* {error && <Error err={error} module="List Applications" />} */}
    </Paper>
    // </div>
  );
};

export default ListApplications;
