import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { format, getDate } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import styles from "./Reports.module.css";

const ReportCard = (props) => {
  const { reportData } = props;

  return (
    <div className={styles.container}>
      <h1>KPI Dashboard</h1>

      {/* display the dashboard */}
      <Grid
        container
        gap={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {reportData &&
          reportData.map((item) => (
            <Grid item xs={12} md={3} key={uuidv4()}>
              <div className={styles.grid}>
                <p className={styles.title}>{item.status}</p>
                {item.status !== "Approved" && (
                  <p className={styles.value}>
                    ${(item.total / 1000).toFixed(2)} {"K"}
                  </p>
                )}
                {item.status === "Approved" && (
                  <>
                    <span className={styles.value}>
                      ${(item.approvedSum / 1000).toFixed(2)} {"K"}
                    </span>
                    <span className={styles.subTitle}>Total Sum Applied</span>
                    <span className={styles.value}>
                      ${(item.total / 1000).toFixed(2)} {"K"}
                    </span>
                    <span className={styles.subTitle}>Total Sum Approved</span>
                    {/* <p className={styles.apps}>
                      {((1 - item.approvedSum / item.total) * 100).toFixed(2)}%
                      Reduction
                    </p> */}
                  </>
                )}
                {item.status !== "Approved"
                  ? item.status !== "Declined" && (
                      <p className={styles.title}>
                        Age: {item.age} {"Days"}
                      </p>
                    )
                  : null}
                <p className={styles.apps}>
                  {item.count} {"Applications"}
                </p>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default ReportCard;
