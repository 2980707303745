import React, { useState } from "react";
import { useField } from "formik";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import styles from "./FileInput.module.css";

const FileInput = ({
  label,
  maxFiles = 6,
  // accept = "'image/*': []",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const { name } = field;
  // const [acceptedTypes, setAcceptedTypes] = useState(
  //   "image/png, image/jpeg, image/gif, application/pdf"
  // );

  const onDrop = (acceptedFiles) => {
    
    // setFiles([...files, ...acceptedFiles]);
    setValue(acceptedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    maxSize: 10485760, // 10 MB in bytes
    maxFiles: maxFiles,
  });

  const selectedFiles = "";

  return (
    <div className={styles.container}>
      <label htmlFor={name}>
        <strong>{label}</strong>
      </label>
      <br></br>
      <label>
        <small>Maximum number of files: {maxFiles}</small>
      </label>
      <div className={styles.dz} {...getRootProps()}>
        {/* <div {...getRootProps({ style })}> */}
        <input {...getInputProps()} name={name} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p styles={{ marginTop: "0px" }}>
            Click to select files or Drag and drop some files here
          </p>
        )}
      </div>
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}

      {field.value?.length > 0 && (
        <ul>
          {field.value.map((file, i) => (
            // <>
            <li key={uuidv4()}>
              {/* {i + 1}
              {". "} */}
              {file.name} | {Number(file.size / 1024 / 1024).toFixed(2)} {"MB"}
            </li>
            // </>
          ))}
        </ul>
      )}

      {/* display files and url in view Mode */}
      {/* {field.value?.length > 0 && (
        <div>
          {Object.entries(field.value).map(([key, value]) => (
            <div key={key}>
              <a href={value}>
                {field.name}-{key}
              </a>
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default FileInput;
