import React from "react";

import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";

import styles from "./Highlight.module.css";

const Highlight = (props) => {
  const { text } = props;
  return (
    <div className={styles.container}>
      <span>
        <TipsAndUpdatesOutlinedIcon fontSize="large" />
      </span>
      <span className={styles.txt}>
        <strong>{text}</strong>
      </span>
    </div>
  );
};

export default Highlight;
