import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// firebase imports
import { db } from "../../firebase/config";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import UsersForm from "./UsersForm";

const DOMAIN = "mfl.com.fj";
const USER_COLLECTION = "users";
const ROLE_COLLECTION = "roles";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState("");
  // const [roles, setRoles] = useState([{id:"","Please select a user -->>"}]);

  const notifyUser = (notification) => {
    toast.info(notification, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const getBackendUsers = async () => {
    // get the organisation users
    try {
      const usersRef = collection(db, USER_COLLECTION);
      const q = query(usersRef, where("domain", "==", DOMAIN));
      const result = await getDocs(q);

      let appUsers = [];

      result.forEach((doc) => {
        appUsers.push({
          id: doc.id,
          user: doc.data().email,
          email: doc.data().email,
          roles: doc.data().roles,
        });
      });

      setUsers(appUsers);
    } catch (error) {
      console.log(error.message);
    }

    // get the user roles
    try {
      const results = await getDocs(collection(db, ROLE_COLLECTION));

      let userRoles = [];
      results.forEach((doc) => {
        // update the users object array
        userRoles.push({ id: doc.id, role: doc.data().role });
      });

      setRoles(userRoles);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateUserRole = async (userId, roles) => {
    try {
      const usersRef = doc(db, USER_COLLECTION, userId);

      await updateDoc(usersRef, {
        roles: roles,
      });

      notifyUser("The user's roles have been updated successfully!", "success");

      getBackendUsers();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getBackendUsers();
  }, []);

  return (
    <div>
      {users && roles && (
        <UsersForm
          users={users}
          roles={roles}
          updateUserRole={updateUserRole}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default Users;
