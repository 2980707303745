import { useState } from "react";
import { Button, Divider, Grid, Paper } from "@mui/material";
import { Form, Formik } from "formik";
import Error from "../../components/error/Error";
import Textfield from "../../components/FormUI/Textfield";
import { useLogIn } from "../../hooks/useLogIn";
import { Link, useNavigate } from "react-router-dom";
import { useSignInWithGoogle } from "../../hooks/useSignInWithGoogle";
import { useSignInWithFacebook } from "../../hooks/useSignInWithFacebook";
import { useSignInWithMicrosoft } from "../../hooks/useSignInWithMicrosoft";

import * as Yup from "yup";

import GoogleSignIn from "../../assets/g-logo.png";
import MSSignIn from "../../assets/ms-symbollockup_signin_light.png";
import FacebookIcon from "@mui/icons-material/Facebook";

import styles from "./Login.module.css";
import SubmitButton from "../../components/FormUI/SubmitButton";

export default function Login() {
  const { error, logIn } = useLogIn();
  const {
    error: _g_error,
    isPending: _g_isPending,
    signInWithGoogle,
  } = useSignInWithGoogle();
  const {
    error: _fb_error,
    isPending: _fb_isPending,
    signInWithFacebook,
  } = useSignInWithFacebook();
  const { signInWithMS, error: msError } = useSignInWithMicrosoft();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required!"),
    password: Yup.string()
      .min(8, "Password is too short!")
      .max(15, "Password is too long!")
      .required("Password is required!"),
  });

  const handleSubmit = async (formValues) => {
    setIsLoading(true);
    try {
      const res = await logIn(formValues.email, formValues.password);
      navigate("/");
    } catch (err) {
      console.log("Error", err.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Paper className={styles.container}>
        {/* <h2>Sign In</h2> */}

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {
            <Form className={styles.form}>
              <h3>Sign In</h3>
              <Grid container gap={1} flexDirection="column">
                {/* <div> */}
                {/* <p>
                    Please provide your email address to access the MFL Loans
                    Portal.<br></br> This email will be used to give you access
                    and also provide all updates about your loan application.
                  </p> */}

                {/* Sign In with Google */}
                <Grid item xs={12}>
                  <Button
                    type="button"
                    onClick={signInWithGoogle}
                    sx={{ textTransform: "none" }}
                    className={styles.google}
                    variant="outlined"
                  >
                    <img
                      src={GoogleSignIn}
                      height="18px"
                      alt="Sign in with Google"
                    />
                    Sign in with Google
                  </Button>
                </Grid>
                {/* </div> */}

                {/* signin with facebook */}
                <div>
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      onClick={signInWithFacebook}
                      variant="outlined"
                      startIcon={<FacebookIcon />}
                      sx={{ textTransform: "none" }}
                    >
                      Sign in with Facebook
                    </Button>
                  </Grid>
                </div>

                {/* Sign In with Microsoft */}
                <Grid item xs={12}>
                  <div onClick={signInWithMS} style={{ cursor: "pointer" }}>
                    <img
                      src={MSSignIn}
                      height="auto"
                      styles={{ cursor: "pointer" }}
                    />
                  </div>
                </Grid>

                <div>
                  <Grid item>
                    <Divider>or</Divider>
                  </Grid>
                </div>

                {/* sign in with existing account */}
                <div>
                  <Grid item xs={12}>
                    <Textfield label="Email" name="email" />
                  </Grid>
                </div>
                <div>
                  <Grid item xs={12}>
                    <Textfield
                      type="password"
                      label="Password"
                      name="password"
                    />
                  </Grid>
                </div>
                <div>
                  <Grid item xs={12}>
                    {/* <SubmitButton disabled={isLoading}>
                      Send Link to my Email
                    </SubmitButton> */}
                    <SubmitButton disabled={isLoading}>Sign In</SubmitButton>
                  </Grid>
                  <div className={styles.signup}>
                    <Grid item>
                      {" "}
                      <span>
                        Don't have an account?{" "}
                        <Link to="/signup">Create Account</Link>
                      </span>
                    </Grid>
                  </div>
                </div>

                {/* {error && (
                  <div>
                    <Grid item>
                      <Error err={error} module="Login" />
                    </Grid>
                  </div>
                )}
                {_g_error && (
                  <div>
                    <Grid item>
                      <Error err={_g_error} module="Login" />
                    </Grid>
                  </div>
                )}

                {_fb_error && (
                  <div>
                    <Grid item>
                      <Error err={_fb_error} module="Login" />
                    </Grid>
                  </div>
                )} */}
              </Grid>
            </Form>
          }
        </Formik>
      </Paper>
    </div>
  );
}
