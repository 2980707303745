import Application from "../application/Application";

const Home = () => {
  return (
    <section>
      <Application />
    </section>
  );
};

export default Home;
