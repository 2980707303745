import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";

const Select = ({ name, options, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, mata] = useField(name);

  useEffect(() => {
    // Set the first option as default if no value is set
    if (field) {
      if (!field.value) {
        const firstOptionKey = Object.keys(options)[0];
        setFieldValue(name, firstOptionKey);
      }
    }
  }, []);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: "outlined",
    fullWidth: true,
    size: "small",
    onChange: handleChange,
  };

  if (mata && mata.touched && mata.error) {
    configSelect.error = true;
    configSelect.helperText = mata.error;
  }

  return (
    <TextField {...configSelect} style={{ textAlign: "left" }}>
      {Object.keys(options).map((item, pos) => {
        return (
          <MenuItem key={pos} value={item}>
            {options[item]}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default Select;
