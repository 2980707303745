import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { format } from "date-fns";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import Textfield from "../FormUI/Textfield";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const Approve = (props) => {
  const {
    open,
    handleClose,
    sendMsg,
    firstName,
    lastName,
    otherNames,
    loanAmount,
    originalLoanAmount,
    dateApplied,
    updateFinaliseApproveStatus,
    updateFinaliseDeclineStatus,
    updateFinaliseEscalateStatus,
    saveAssessment,
    isEscalated,
  } = props;
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [finaliseComments, setFinaliseComments] = useState("");
  const [isCommentsError, setIsCommentsError] = useState(false);
  const [finaliseDeclineEMailMsg, setFinaliseDeclineEMailMsg] = useState("");
  const [isEmailMsgError, setIsEmailMsgError] = useState(false);
  const [escalateTo, setEscalateTo] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { id } = useParams();

  let newurl = process.env.REACT_APP_AUTH_DOMAIN;
  const link1 = `https://${newurl}/upl-application/${id}`;
  const link2 = `https://${newurl}/assess/${id}`;

  useEffect(() => {
    setFinaliseComments("");
    setFinaliseDeclineEMailMsg("");
    setEscalateTo("");
  }, [props]);

  const APPROVE_EMAIL =
    "Bula " +
    firstName +
    ",<br /><br />" +
    "Your loan application for $" +
    loanAmount +
    " which you applied for on " +
    format(dateApplied.toDate(), "EEE dd MMM yyyy hh:mm a") +
    " has been provisionally approved :)<br /><br />" +
    "We will be sending your loan offer letter and other documents for signing via email before the close of the next business day i.e. 24 hours.<br />" +
    "Upon return of the signed copies we will do our vetting process and inform you of our final decision.<br /><br />" +
    "Thank you for choosing us as your financial services provider.";

  const APPROVE_EMAIL_NEW_AMOUNT =
    "Bula " +
    firstName +
    ",<br /><br />" +
    "Your loan application for $" +
    originalLoanAmount +
    " which you applied for on " +
    format(dateApplied.toDate(), "EEE dd MMM yyyy hh:mm a") +
    " has been provisionally approved for a lower amount of <strong>$" +
    loanAmount +
    "</strong> :)<br /><br />" +
    "The loan amount was reduced based on our assessment of your ability to make the new loan repayments with your current financial commitments.<br />" +
    "Please confirm whether you accept the new loan offer of $" +
    loanAmount +
    " within the next 3 business days.<br /><br />" +
    "If you do accept the offer, we will be sending your loan offer letter and other documents for signing via email before the close of the next business day i.e. 24 hours.<br />" +
    "Upon return of the signed copies we will do our vetting process and inform you of our final decision.<br /><br />" +
    "Thank you for choosing us as your financial services provider.";

  const DECLINE_EMAIL =
    "Bula " +
    firstName +
    ",<br /><br />" +
    "Your loan application for $" +
    loanAmount +
    " " +
    "sent it on " +
    format(dateApplied.toDate(), "EEE dd MMM yyyy hh:mm a") +
    " has been declined.<br />" +
    finaliseDeclineEMailMsg;

  // add the recommendation and finalise comments
  const ESCALATE_EMAIL =
    "Bula, <br /><br />I am escalating this " +
    "<a href=" +
    link2 +
    ">customer assessment</a>" +
    " " +
    "to you for your consideration.<br /><br />" +
    "Customer: " +
    firstName +
    " " +
    otherNames +
    " " +
    lastName +
    "<br />" +
    "Date Applied: " +
    format(dateApplied.toDate(), "EEE dd/MM/yyyy hh:mm a") +
    "<br />" +
    "Loan Amount: $" +
    loanAmount +
    "<br />" +
    "Comments: " +
    finaliseComments;

  const handleDecline = () => {
    if (finaliseComments.trim() === "") {
      setIsCommentsError(true);
    }

    if (finaliseDeclineEMailMsg.trim() === "") {
      setIsEmailMsgError(true);
    }

    if (
      finaliseComments.trim() !== "" &&
      finaliseDeclineEMailMsg.trim() !== ""
    ) {
      setIsEmailMsgError(false);
      setIsCommentsError(false);

      finalise("Declined");
    }
  };

  const handleApprove = () => {
    if (finaliseComments.trim() === "") {
      setIsCommentsError(true);
    } else {
      setIsCommentsError(false);
      finalise("Approved");
    }
  };

  const handleEscalateChange = (event) => {
    let emailValue = event.target.value;
    setEscalateTo(emailValue);
  };

  const handleEscalate = () => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

    if (escalateTo.trim() === "") {
      setIsEmailError(true);
    }
    if (finaliseComments.trim() === "") {
      setIsCommentsError(true);
    }

    if (finaliseComments.trim() !== "" && escalateTo.trim() !== "") {
      if (regex.test(escalateTo)) {
        setIsEmailError(false);
        setIsCommentsError(false);

        finalise("Escalated");
      } else {
        setIsEmailError(true);
        setIsCommentsError(true);
      }
    }
  };

  const finalise = (decision) => {
    // send the customer email

    if (decision === "Approved") {
      // check to see if loan amount has been reduced
      if (Number(loanAmount) < Number(originalLoanAmount)) {
        // loan amount has been reduced
        sendMsg(APPROVE_EMAIL_NEW_AMOUNT);
      } else {
        // loan amount is the same as applied
        sendMsg(APPROVE_EMAIL);
      }

      // escalator has approved
      if (isEscalated) {
        updateFinaliseApproveStatus(finaliseComments, true);
      } else {
        updateFinaliseApproveStatus(finaliseComments);
      }
    }

    if (decision === "Declined") {
      sendMsg(DECLINE_EMAIL);
      updateFinaliseDeclineStatus(finaliseComments, finaliseDeclineEMailMsg);
    }

    //escalate the application to the next level
    if (decision === "Escalated") {
      // send the email

      sendMsg(ESCALATE_EMAIL, escalateTo);
      updateFinaliseEscalateStatus(finaliseComments, escalateTo);
    }

    // save the assessment loan details and expenses
    saveAssessment(decision);

    // close the Approve modal
    handleClose();
  };

  return (
    <Grid item xs={12}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Finalise Application</DialogTitle>
        <DialogContent>
          <form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                rowGap: ".8em",
                marginTop: ".8em",
              }}
            >
              {/* Fields ========================================================== */}

              {/* Finalise comments */}
              <TextField
                label="Finalise Comments"
                rows={5}
                value={finaliseComments}
                onChange={(e) => setFinaliseComments(e.target.value)}
                placeholder="Provide comments on the finalised application"
                multiline
                maxLength="3000"
                required
                error={isCommentsError}
                helperText={
                  isCommentsError ? "Finalisation comments is required!" : ""
                }
              />

              {/* Decline email message */}
              <TextField
                label="Reason for Decline"
                rows={5}
                value={finaliseDeclineEMailMsg}
                onChange={(e) => setFinaliseDeclineEMailMsg(e.target.value)}
                placeholder="Provide a reason for declining the application which will added to the email to the customer"
                multiline
                maxLength="3000"
                error={isEmailMsgError}
                helperText={
                  isEmailMsgError
                    ? "Decline reason for emailing to customer is required!"
                    : ""
                }
              />

              {/* Escalate To */}
              <TextField
                label="Escalate To"
                type="email"
                size="small"
                value={escalateTo}
                onChange={handleEscalateChange}
                placeholder="Provide an email of the person to escalate to"
                maxLength="40"
                error={isEmailError}
                helperText={
                  isEmailError ? "A valid email address is required!" : ""
                }
              />

              {/* Action buttons ========================================================== */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  columnGap: ".3em",
                }}
              >
                {/* Approve */}
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleApprove}
                  sx={{
                    minWidth: "100px",
                    backgroundColor: "#002a48",
                    textTransform: "capitalize",
                    borderRadius: "25px",
                  }}
                >
                  Approve
                </Button>

                {/* Decline */}
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleDecline}
                  sx={{
                    minWidth: "100px",
                    backgroundColor: "#002a48",
                    textTransform: "capitalize",
                    borderRadius: "25px",
                  }}
                >
                  Decline
                </Button>

                {/* Escalate */}
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleEscalate}
                  sx={{
                    minWidth: "100px",
                    backgroundColor: "#002a48",
                    textTransform: "capitalize",
                    borderRadius: "25px",
                  }}
                >
                  Escalate
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default Approve;
