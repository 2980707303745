import React, { useEffect, useState } from "react";

import { db } from "../../firebase/config";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
  startAt,
  endAt,
} from "firebase/firestore";

import Filter from "./Filter";
import ListApplications from "./ListApplications";
import { Paper } from "@mui/material";
import SearchForm from "./SearchForm";

const MAX_RECS = 200;

const Search = () => {
  const [currentFilter, setCurrentFilter] = useState("Submitted");
  const [stageCount, setStageCount] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apps, setApps] = useState(null);
  const [isSearch, setIsSearch] = useState(false);

  const changeFilter = (newFilter) => {
    setCurrentFilter(newFilter);
  };

  const getFilteredApps = async () => {
    setIsLoading(true);

    try {
      let q;

      const ref = collection(db, "unsecuredLoanApplications");

      if (currentFilter.toLowerCase() == "approved") {
        q = query(
          ref,
          where("status", "==", currentFilter),
          orderBy("dateApplied", "desc"),
          orderBy("firstName", "asc"),
          orderBy("lastName", "asc"),
          limit(MAX_RECS)
        );
      } else {
        q = query(
          ref,
          where("status", "==", currentFilter),
          orderBy("dateApplied", "asc"),
          orderBy("firstName", "asc"),
          orderBy("lastName", "asc"),
          limit(MAX_RECS)
        );
      }

      const res = await getDocs(q);
      const resData = res.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setApps(resData);
      setIsLoading(false);
    } catch (error) {
      setError(error.code + ": " + error.message);
      setIsLoading(false);
      console.log(error.message);
    }
  };

  const fetchSearchResults = async ({ firstName }) => {
    try {
      if (firstName.length <= 2) return;

      setIsLoading(true);
      setIsSearch(true);

      let searchString = firstName.toLowerCase();
      searchString = searchString.charAt(0).toUpperCase();
      searchString = firstName.charAt(0).toUpperCase() + firstName.slice(1);

      const ref = collection(db, "unsecuredLoanApplications");
      const q = query(
        ref,
        where("firstName", ">=", searchString),
        where("firstName", "<=", searchString + "\uf8ff"),
        orderBy("firstName", "asc"),
        limit(MAX_RECS)
      );

      const res = await getDocs(q);

      // const resData = res.docs.map((doc) => doc.data());
      const resData = res.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setApps(resData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.code + ": " + error.message);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    // getFilteredApps();
  }, [currentFilter]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Paper sx={{ width: "95%", padding: "1rem" }} elevation={5}>
        <h1 style={{ paddingTop: "0rem" }}>UPL Applications</h1>
        {/* <Filter
          currentFilter={currentFilter}
          changeFilter={changeFilter}
          // stageCount={stageCount}
          isLoading={isLoading}
        /> */}
        <SearchForm
          fetchSearchResults={fetchSearchResults}
          isLoading={isLoading}
        />
        <ListApplications
          apps={apps}
          currentFilter={currentFilter}
          isSearch={isSearch}
        />
        {error && <p>{error}</p>}
      </Paper>
    </div>
  );
};

export default Search;
