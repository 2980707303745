import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>
        <span className={styles.copyright}>
          &copy; {new Date().getFullYear()} Merchant Finance Limited. All Rights
          <br />
          {process.env.REACT_APP_AUTH_DOMAIN}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
