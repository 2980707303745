import React, { useState } from "react";
import { Button } from "@mui/material";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// firebase imports
import { db } from "../../firebase/config";
import { doc, updateDoc } from "firebase/firestore";

const ChangeStatus = (props) => {
  const [error, setError] = useState(null);
  const { id } = props;

  const notifyUser = (notification) => {
    toast.info(notification, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const handleClick = async () => {
    // change the status to Assessing
    // and reset fields
    try {
      const docRef = doc(db, "unsecuredLoanApplications", id);
      const res = await updateDoc(docRef, {
        status: "Assessing",
        dateDeclined: "",
        declineReason: "",
        declineOtherReason: "",
        decline_uid: "",
        finaliseDecline_uid: "",
        dateFinaliseDeclined: "",
        finaliseComments: "",
        finaliseDeclineEmailMsg: "",
        dateFinaliseEscalated: "",
        escalatedTo: "",
        finaliseEscalate_uid: "",
        recommendation: "",
        recommend_uid: "",
        dateRecommended: "",
        dateEscalatedToApproved: "",
        escalatedToComments: "",
        escalatedToApprove_uid: "",
        dateApproved: "",
        finaliseApprove_uid: "",
      });

      notifyUser(
        "The application status has been changed successfully to Assessing!"
      );
    } catch (error) {
      setError(error.code + ": " + error.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "2rem",
      }}
    >
      <Button
        onClick={() => handleClick()}
        sx={{
          maxWidth: "250px",
          textTransform: "capitalize",
          backgroundColor: "#002a48",
          borderRadius: "25px",
          size: "small",
        }}
        variant="contained"
      >
        <span style={{ marginRight: "5px" }}>
          <RotateLeftOutlinedIcon />
        </span>
        {"   "}
        <span>Re-Assess</span>
      </Button>
      <ToastContainer />
    </div>
  );
};

export default ChangeStatus;
