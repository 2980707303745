import { useState, useEffect } from "react";

//firebase imports
import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";

const useGetDocs = (collectionName) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getDocuments = async () => {
      try {
        setIsLoading(true);
        let result = [];

        const docSnap = await getDocs(collection(db, collectionName));
        docSnap.forEach((doc) => {
          result.push({ ...doc.data(), id: doc.id });
        });

        setData({ ...result });
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
      setIsLoading(false);
    };

    if (collectionName) {
      getDocuments();
    }
  }, [collectionName]);

  // return { data, isLoading, error };
  return { data, error };
};

export default useGetDocs;
