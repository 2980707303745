import React, { useState } from "react";
import FileSelector from "../../components/FormUI/FileSelector";
import { Button, Grid } from "@mui/material";

// firebase imports
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { db } from "../../firebase/config";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";

import Highlight from "../../components/highlight/Highlight";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomerDocs = ({ id, getApplication }) => {
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const notifyUser = (notification) => {
    toast.info(notification, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const handleUpload = () => {
    const storage = getStorage();
    let fileDetails = {};

    const folderName = "assesorDocs";

    // diable the upload button
    setIsUploading(true);

    images.forEach((image) => {
      let storageRef = ref(
        storage,
        `reqs/upl/${id}/${folderName}/${image.name}`
      );

      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(uploadProgress);
        },
        (error) => {
          console.error("Error uploading image:", error);
          setError("Error uploading image:", error.message);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            fileDetails = { file: image.name, url: downloadURL };

            setUrls((prev) => [
              ...prev,
              {
                file: image.name,
                url: downloadURL,
              },
            ]);
          } catch (error) {
            console.error("Error getting download url:", error);
            setError("Error getting URLs: ", error.message);
          } finally {
            setIsUploading(false);
          }

          saveURLs(fileDetails);
        }
      );
    });
  };

  const saveURLs = async (fileDetails) => {
    // change the status to Assessing
    // and reset fields

    setIsUploading(true);

    try {
      const docRef = doc(db, "unsecuredLoanApplications", id);
      const res = await updateDoc(docRef, {
        assessorDocs: arrayUnion(fileDetails),
      });

      getApplication();
      // notify user
      notifyUser("The file(s) have been uploaded successfully!");

      setImages([]);
      setUrls([]);
      setProgress(0);
      setError(null);
    } catch (err) {
      setError("Error saving URLs: ", err.message);
      console.log("Error Saving urls", err.message);
    }

    setIsUploading(false);
  };

  return (
    <div
      style={{
        border: "1px solid #002a48",
        padding: "1rem",
        borderRadius: "5px",
      }}
    >
      <h1>Customer Documents</h1>
      <Grid container>
        <Grid item xs={12}>
          <Highlight text="Select the files needed to support the loan application. Max Files: 5, Max Size: 10MB" />
        </Grid>
        <Grid item xs={12}>
          <FileSelector files={images} setFiles={setImages} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <div>
            {progress > 0 && (
              <>
                <p>
                  <strong>Upload Progress:</strong>
                </p>
                <span>
                  <progress value={progress} max="100" /> {progress.toFixed(0)}%
                </span>{" "}
                <br />
              </>
            )}
          </div>
          <br />
          {/* upload action */}
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              variant="contained"
              type="button"
              onClick={handleUpload}
              style={{ textTransform: "capitalize" }}
              disabled={isUploading || images.length === 0}
            >
              Upload Images
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          {error && <p>{error}</p>}
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
  );
};

export default CustomerDocs;
