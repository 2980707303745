import React from "react";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../components/FormUI/ActionButton";

import styles from "./Loans.module.css";

const Loans = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <ActionButton onClick={() => navigate("/new-upl-application")}>
          Unsecured Loan
        </ActionButton>
        {/* <ActionButton className={styles.btn}>Car Loan</ActionButton> */}
      </div>
    </div>
  );
};

export default Loans;
