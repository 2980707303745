import React from "react";
import { Form, Formik } from "formik";

import Textfield from "../../components/FormUI/Textfield";
import ActionButton from "../../components/FormUI/ActionButton";

const SearchForm = (props) => {
  const { fetchSearchResults, isLoading } = props;
  const initialValues = {
    firstName: "",
  };

  const handleSubmit = (values) => {
    fetchSearchResults(values);
  };

  return (
    <div className="container-horizontal">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className="form-horizontal">
          <Textfield name="firstName" label="First Name" />
          <ActionButton type="submit" disabled={isLoading}>
            Search
          </ActionButton>
        </Form>
      </Formik>
    </div>
  );
};

export default SearchForm;
