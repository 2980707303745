import { useField } from "formik";
import TextField from "@mui/material/TextField";

const Textfield = (props) => {
  const { name, maxLength, ...otherProps } = props;
  const [field, meta] = useField(name);

  const configTextField = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    size: "small",
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return <TextField {...configTextField} inputProps={{ maxLength }} />;
};

export default Textfield;
