import React, { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  updateProfile,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

const provider = new OAuthProvider("microsoft.com");

export const useSignInWithMicrosoft = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [simpleDisplayName, setSimpleDisplayName] = useState("");
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const signInWithMS = async () => {
    const auth = getAuth();
    try {
      setIsPending(true);
      const res = await signInWithPopup(auth, provider);
      // Signed in successfully
      const credential = OAuthProvider.credentialFromResult(res);
      const token = credential.accessToken;
      const user = res.user;

      dispatch({ type: "LOGIN", payload: res.user });

      setIsPending(false);

      // send them to the home page
      navigate("/");
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = OAuthProvider.credentialFromError(error);
      // ...
      setError(errorCode + ": " + errorMessage);
      // console.log("errorCode==>", errorCode);
      console.log("errorMessage==>" + error.message);
      setIsPending(false);
    }
  };

  return { error, isPending, signInWithMS };
};

export default useSignInWithMicrosoft;
