import React from "react";

const DisplayExtraInfo = (props) => {
  const { extraInfo } = props;

  return (
    <section style={{ textAlign: "left" }}>
      <hr />
      <p>
        <strong>Referee Name:</strong> {extraInfo.referees?.ref1Name}
      </p>
      <p>
        <strong>Referee Relationship:</strong>{" "}
        {extraInfo.referees?.ref1Relationship}
      </p>
      <p>
        <strong>Referee Occupation:</strong>{" "}
        {extraInfo.referees?.ref1Occupation}
      </p>
      <p>
        <strong>Referee Address:</strong> {extraInfo.referees?.ref1Address}
      </p>
      <p>
        <strong>Referee Email:</strong> {extraInfo.referees?.ref1Email}
      </p>
      <p>
        <strong>Referee Mobile:</strong> {extraInfo.referees?.ref1Mobile}
      </p>

      <hr />
      <p>
        <strong>Referee Name:</strong> {extraInfo.referees?.ref2Name}
      </p>
      <p>
        <strong>Referee Relationship:</strong>{" "}
        {extraInfo.referees?.ref2Relationship}
      </p>
      <p>
        <strong>Referee Occupation:</strong>{" "}
        {extraInfo.referees?.ref2Occupation}
      </p>
      <p>
        <strong>Referee Address:</strong> {extraInfo.referees?.ref2Address}
      </p>
      <p>
        <strong>Referee Email:</strong> {extraInfo.referees?.ref2Email}
      </p>
      <p>
        <strong>Referee Mobile:</strong> {extraInfo.referees?.ref2Mobile}
      </p>
    </section>
  );
};

export default DisplayExtraInfo;
