import {
  Box,
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";

import { useAuthContext } from "../../hooks/useAuthContext";
import useGetDoc from "../../hooks/useGetDoc";

import styles from "./ViewApplication.module.css";
import ChangeStatus from "./ChangeStatus";
import ClosingDocsDialogue from "./ClosingDocsDialogue";
import CustomerDocs from "../customerDocs/CustomerDocs";
import DisplayExtraInfo from "../extraInfo/DisplayExtraInfo";
import ExtraInfoForm from "../extraInfo/ExtraInfoForm";

const ViewApplication = (props) => {
  const {
    data,
    assessmentData,
    updateApp,
    openExtraInfo,
    setOpenExtraInfo,
    showCustomerDocs,
    getApplication,
  } = props;

  const [isApproved, setIsApproved] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isAssessor, setIsAssessor] = useState(false);
  const [isAssessing, setIsAssessing] = useState(false);
  const [isCompliance, setIsCompliance] = useState(false);
  const [statusRecommend, setStatusRecommend] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [hasRoles, setHasRoles] = useState(false);
  const { user } = useAuthContext();
  const [isEscalated, setIsEscalated] = useState(false);
  const [isEscalatedTo, setIsEscalatedTo] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  //get the roles associated with the current user
  const { data: userData } = useGetDoc("users", user.uid);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // update the upl application that the closing docs details have been downloaded

  useEffect(() => {
    if (userData && userData.roles.length > 0) {
      setHasRoles(true);
    }

    if (
      data.escalatedToApprove_uid &&
      data.escalatedToApprove_uid.trim !== ""
    ) {
      setIsEscalatedTo(true);
    }

    // ===========================================================

    if (userData && userData.roles.includes("approver")) {
      setIsApprover(true);
    }

    if (userData && userData.roles.includes("assessor")) {
      setIsAssessor(true);
    }

    if (userData && userData.roles.includes("compliance")) {
      setIsCompliance(true);
    }
    if (data) {
      if (data.dateRecommended && data.dateRecommended !== "") {
        setIsRecommended(true);
      }

      if (data.dateApproved && data.dateApproved !== "") {
        setIsApproved(true);
      }

      if (data.dateEscalatedToApproved && data.dateEscalatedToApproved !== "") {
        setIsApproved(true);
      }

      if (
        (data.dateDeclined && data.dateDeclined !== "") ||
        (data.dateFinaliseDeclined && data.dateFinaliseDeclined !== "")
      ) {
        // console.log("Decline date not null");
        setIsDeclined(true);
      }

      if (data.status === "Declined") {
        setIsDeclined(true);
      }

      if (data.status === "Recommended") {
        setStatusRecommend(true);
      }

      if (data.status === "Assessing") {
        setIsAssessing(true);
      }

      if (data.status === "Approved") {
        setIsApproved(true);
      }

      if (data.dateFinaliseEscalated && data.dateFinaliseEscalated !== "") {
        setIsEscalated(true);
      }
    }

    // console.log("xxx===>>>", data);
  }, [data, userData, assessmentData]);

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const installmentCalc = (freq) => {
    let freqNum = 1;
    if (freq === "weekly") {
      freqNum = 4;
    }
    if (freq === "monthly" || freq === "bimonthly") {
      freqNum = 1;
    }
    if (freq === "fortnightly") {
      freqNum = 2;
    }

    return freqNum;
  };

  const saveToJson = (
    startDate,
    effectiveInterestRate,
    repaymentMethod,
    installment,
    mflBankAccountNumber,
    firstName,
    otherNames,
    lastName
  ) => {
    try {
      const paybackToBorrowingRatio = (
        Number(assessmentData.totalRepayable) /
        Number(assessmentData.totalAmountFinanced)
      ).toFixed(2);

      let installmentAmt = Number(
        parseFloat(assessmentData.monthlyInstallment) /
          parseFloat(installmentCalc(data.payFrequency))
      ).toFixed(2);

      let freq = Number(installmentCalc(data.payFrequency));
      console.log("freq", freq);

      let surchargeInstallment = Number(
        parseFloat(assessmentData.surcharge) / freq
      ).toFixed(2);

      let installmentWithSurcharge =
        Number(surchargeInstallment) + Number(installmentAmt);
      console.log("installmentAmt", installmentAmt);
      console.log("surchargeInstallment", surchargeInstallment);
      console.log(
        "installmentWithSurcharge",
        Number(installmentWithSurcharge).toFixed(2)
      );

      let docsData = {
        // personal details
        firstName: toTitleCase(firstName.trim()),
        otherNames: toTitleCase(otherNames.trim()),
        lastName: toTitleCase(lastName.trim()),
        customerName: toTitleCase(
          firstName.trim() + " " + otherNames.trim() + " " + lastName.trim()
        ),
        residentialAddress: data.residentialAddress1.trim(),
        postalAddress: data.postalAddress.trim().toUpperCase(),
        email: data.email.trim(),
        mobile: data.mobile,
        dateOfBirth: format(new Date(data.dateOfBirth), "dd MMM yyyy"),
        maritalStatus: toTitleCase(data.maritalStatus),
        //emp details
        occupation: toTitleCase(data.occupation.trim()),
        fnpf: data.fnpf.trim(),
        tin: data.tin,
        employer: data.employer.trim().toUpperCase(),
        employerAddress: toTitleCase(data.employerAddress.trim()),
        payFrequency: toTitleCase(data.payFrequency),
        // bank details
        bank: data.bank.toUpperCase(),
        branch: toTitleCase(data.branch.trim()),
        accountName: data.accountName.trim(),
        accountNumber: data.accountNumber.trim(),
        // loan details
        loanAmount: `${USDollar.format(assessmentData.loanAmount)}`,
        loanTerm: assessmentData.loanTerm,
        monthlyInstallment: `${USDollar.format(
          assessmentData.monthlyInstallment
        )}`,
        interestRate: assessmentData.interestRate + "%",
        insuranceRate: assessmentData.insuranceRate + "%",
        estFeeRate: assessmentData.estFeeRate + "%",
        // fees
        monthlyRepayment: `${USDollar.format(
          assessmentData.monthlyInstallment
        )}`,
        estFee: `${USDollar.format(assessmentData.estFee)}`,
        loanInsurance: `${USDollar.format(assessmentData.loanInsurance)}`,
        totalAmountFinanced: `${USDollar.format(
          assessmentData.totalAmountFinanced
        )}`,
        totalInterest: `${USDollar.format(assessmentData.totalInterest)}`,
        totalRepayable: `${USDollar.format(assessmentData.totalRepayable)}`,
        surcharge: `${USDollar.format(assessmentData.surcharge)}`,
        surchargeRate: assessmentData.surchargeRate + "%",
        paybackToBorrowingRatio: paybackToBorrowingRatio.toString(),
        //other details
        paymentStartDate: format(new Date(startDate), "dd MMM yyyy"),
        effectiveInterestRate: effectiveInterestRate + "%",
        repaymentMethod: repaymentMethod,
        installment: `${USDollar.format(installmentAmt)}`,
        mflBankAccountNumber: mflBankAccountNumber,
        docDate: format(new Date(), "EEE dd MMM yyyy"),
        // do the periodic installment + the periodic surcharge
        surchargeInstallment: `${USDollar.format(surchargeInstallment)}`,
        installmentWithSurcharge: `${USDollar.format(
          installmentWithSurcharge
        )}`,
      };

      // Save data to JSON file
      const json = JSON.stringify(docsData);
      const blob = new Blob([json], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = data.firstName + "-" + data.tin + ".json";
      link.click();

      // console.log("Data saved to JSON file", docsData);
    } catch (error) {
      // console.error("Error fetching data:", error);
      setError(error.code + ": " + error.message);
      console.log(error);
    }
  };

  let extraInfo = {};
  if (data?.referees) extraInfo = data?.referees;
  if (data?.repaymentMode) extraInfo.repaymentMode = data?.repaymentMode;

  console.log("extraInfo------------>>>", extraInfo);

  return (
    <div className={styles.container}>
      <h1>UPL Application</h1>

      <Paper
        sx={{ width: "90%", margin: "0 auto", padding: "1rem" }}
        elevation={5}
      >
        {showCustomerDocs && (
          <CustomerDocs id={id} getApplication={getApplication} />
        )}

        {/* Extra Info dialog ------------------------------ */}

        <Dialog open={openExtraInfo} sx={{ padding: 2, m: 2 }}>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <h2
              style={{
                // paddingLeft: "1rem",
                marginBottom: "0px",
                paddingBottom: "0",
              }}
            >
              Extra Information
            </h2>
            <button
              style={{ height: "20px" }}
              onClick={() => setOpenExtraInfo(false)}
            >
              X
            </button>
          </div>

          <ExtraInfoForm updateApp={updateApp} extraInfo={extraInfo} />
        </Dialog>

        {/* End of Extra Info dialog ------------------------------ */}

        <ClosingDocsDialogue
          open={open}
          onClose={handleClose}
          saveToJson={saveToJson}
          fName={toTitleCase(data.firstName)}
          oNames={toTitleCase(data.otherNames)}
          lName={toTitleCase(data.lastName)}
          id={id}
        />

        {/* show the Change Status for a declined application  */}
        {/* if the user is an approver */}
        {isApprover && isDeclined && <ChangeStatus id={id} />}

        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "1rem" }}
        >
          <h2>
            {data.firstName} {data.lastName}
          </h2>

          <Tabs>
            <TabList>
              <Tab>Application Details</Tab>
              <Tab>Personal Details</Tab>
              <Tab>Expenses</Tab>
              <Tab>Bank Details</Tab>
              <Tab>Referees</Tab>
            </TabList>

            {/* application details */}
            <TabPanel>
              <div>
                {/* show the download application button */}
                {isCompliance && isApproved && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: ".5rem",
                      marginTop: ".5rem",
                    }}
                  >
                    {/* download application details */}
                    <Button
                      onClick={handleOpen}
                      sx={{
                        maxWidth: "250px",
                        textTransform: "capitalize",
                        backgroundColor: "#002a48",
                        borderRadius: "25px",
                        size: "small",
                      }}
                      variant="contained"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>Get Details</span>
                      </div>
                    </Button>
                  </div>
                )}
              </div>

              <TableContainer sx={{ width: "95%", margin: "0 auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* personal details ===================================================== */}
                    <TableRow>
                      <TableCell colSpan={4} sx={{ background: "#f2f2f2" }}>
                        <strong>Personal Details</strong>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>
                          {/* Customer Status: */}
                          Type:
                        </div>
                        <div>{data.mflCustomerStatus}</div>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <div style={{ fontWeight: "bold" }}>Name:</div>
                        <div>
                          {data.firstName} {data.otherNames} {data.lastName}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Date Applied:</div>
                        <div>
                          {format(
                            new Date(data.dateApplied.toDate()),
                            "EEE dd MMM yyyy hh:mm a"
                          )}
                        </div>
                      </TableCell>
                    </TableRow>

                    {/* Employment details ===================================================== */}
                    <TableRow>
                      <TableCell colSpan={4} sx={{ background: "#f2f2f2" }}>
                        <strong>Employment Details</strong>
                      </TableCell>
                    </TableRow>

                    {/* employer details */}
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div style={{ fontWeight: "bold" }}>Employer</div>
                        <div>{data.employer}</div>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <div style={{ fontWeight: "bold" }}>Address</div>
                        <div>{data.employerAddress}</div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Duration</div>
                        <div>{data.durationOfEmployment1}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Pay Cycle</div>
                        <div>{toTitleCase(data.payFrequency)}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Occupation</div>
                        <div>{data.occupation}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Annual Salary</div>
                        <div>{USDollar.format(data.annualSalary)}</div>
                      </TableCell>
                    </TableRow>

                    {/* previous employer details */}
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div style={{ fontWeight: "bold" }}>Previous</div>
                        <div>{data.previousEmployer}</div>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <div style={{ fontWeight: "bold" }}>Duration</div>
                        <div>{data.durationOfEmployment2}</div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={1}>
                        <div style={{ fontWeight: "bold" }}>FNPF</div>
                        <div>{data.fnpf}</div>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <div style={{ fontWeight: "bold" }}>TIN</div>
                        <div>{data.tin}</div>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <div style={{ fontWeight: "bold" }}>
                          Monthly Extra Income
                        </div>
                        <div>
                          {USDollar.format(
                            data.additionalIncome ? data.additionalIncome : "0"
                          )}
                        </div>
                      </TableCell>
                    </TableRow>

                    {/* loan details ===================================================== */}
                    <TableRow>
                      <TableCell colSpan={4} sx={{ background: "#f2f2f2" }}>
                        <strong>Loan Details</strong>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={1}>
                        <div style={{ fontWeight: "bold" }}>Amount:</div>
                        <div>{USDollar.format(data.loanAmount)}</div>
                      </TableCell>

                      <TableCell colSpan={1}>
                        <div style={{ fontWeight: "bold" }}>Term:</div>
                        <div>{data.loanTerm} months</div>
                      </TableCell>

                      <TableCell colSpan={1}>
                        <div style={{ fontWeight: "bold" }}>Status:</div>
                        <div>{data.status}</div>
                      </TableCell>

                      <TableCell colSpan={1}>
                        <div style={{ fontWeight: "bold" }}>
                          Repayment Mode:
                        </div>
                        <div>{data?.repaymentMode}</div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={4}>
                        <div style={{ fontWeight: "bold" }}>Loan Purpose:</div>
                        <div>{data.loanPurpose}</div>
                      </TableCell>
                    </TableRow>

                    {/* Finalised loan details ===================================================== */}
                    {isApproved && (
                      <>
                        <TableRow>
                          <TableCell colSpan={4} sx={{ background: "#f2f2f2" }}>
                            <strong>Finalised Loan Details</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <div style={{ fontWeight: "bold" }}>
                              Loan Amount:
                            </div>
                            <div>
                              {USDollar.format(assessmentData.loanAmount)}
                            </div>
                          </TableCell>
                          <TableCell colSpan={2}>
                            <div style={{ fontWeight: "bold" }}>
                              {" "}
                              Loan Term:
                            </div>
                            <div>{assessmentData.loanTerm} months</div>
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    {/* expenses */}
                    {/* expenses ===================================================== */}
                    {/* <TableRow>
                      <TableCell colSpan={4} sx={{ background: "#f2f2f2" }}>
                        <strong>Expenses</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Rent</div>
                        <div>${data.rent}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Electricity</div>
                        <div>${data.electricity}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Water</div>
                        <div>${data.water}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Mobile Spend</div>
                        <div>${data.mobileSpend}</div>
                      </TableCell>
                    </TableRow> */}

                    {/* expenses */}
                    {/* <TableRow>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Groceries</div>
                        <div>${data.groceries}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>School Fees</div>
                        <div>${data.schoolFees}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Transport</div>
                        <div>${data.travel}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Subsciptions</div>
                        <div>${data.subscriptions}</div>
                      </TableCell>
                    </TableRow> */}

                    {/* expenses */}
                    {/* <TableRow>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Entertainment</div>
                        <div>${data.entertainment}</div>
                      </TableCell>

                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Insurance</div>
                        <div>${data.insurance}</div>
                      </TableCell>

                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Home Loans</div>
                        <div>${data.homeLoans}</div>
                      </TableCell>

                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Other Loans</div>
                        <div>${data.otherLoans}</div>
                      </TableCell>
                    </TableRow> */}

                    {/* expenses */}
                    {/* <TableRow>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Hire Purchase</div>
                        <div>${data.hirePurchase}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Other Expenses</div>
                        <div>${data.otherExpenses}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Credit Cards</div>
                        <div>${data.creditCards}</div>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow> */}

                    {/* files ===================================================== */}
                    <TableRow>
                      <TableCell colSpan={4} sx={{ background: "#f2f2f2" }}>
                        <strong>Files</strong>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Signature</div>
                        <div>
                          <ul>
                            {data.signature.map((pid, i) => (
                              <li key={uuidv4()}>
                                <a
                                  href={pid}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  signature-{i + 1}
                                </a>
                                <br></br>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Photo IDs</div>
                        <div>
                          <ul>
                            {data.photoIDs.map((pid, i) => (
                              <li key={uuidv4()}>
                                <a
                                  href={pid}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  photoIDs-{i + 1}
                                </a>
                                <br></br>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>
                          Employment Confirmation
                        </div>
                        <div>
                          <ul>
                            {data.employmentConfirmation.map((pid, i) => (
                              <li key={uuidv4()}>
                                <a
                                  href={pid}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  empConfirm-{i + 1}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>TIN</div>
                        <div>
                          <ul>
                            {data.tinLetterCard.map((pid, i) => (
                              <li key={uuidv4()}>
                                <a
                                  href={pid}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  tin-{i + 1}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </TableCell>
                    </TableRow>

                    {/* files */}
                    <TableRow>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>
                          2 Latest Payslips
                        </div>
                        <div>
                          <ul>
                            {data.payslips.map((pid, i) => (
                              <li key={uuidv4()}>
                                <a
                                  href={pid}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  payslips-{i + 1}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontWeight: "bold" }}>Bank Statement</div>
                        <div>
                          <ul>
                            {data.bankStatement.map((pid, i) => (
                              <li key={uuidv4()}>
                                <a
                                  href={pid}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  bankStatement-{i + 1}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </TableCell>
                      <TableCell colSpan={2} align="center">
                        <div style={{ fontWeight: "bold" }}>
                          Other loans, hire purchases, credit card, or
                          additional income statements or supporting documents
                          for your application
                        </div>
                        <div>
                          <ul>
                            {data.otherStatements.map((pid, i) => (
                              <li key={uuidv4()}>
                                <a
                                  href={pid}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  otherStatements-{i + 1}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </TableCell>
                    </TableRow>

                    {data.assessorDocs && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <p>
                            <strong>Assessor Documents</strong>
                          </p>
                          <ul>
                            {data.assessorDocs.map((pid, i) => (
                              <li key={uuidv4()}>
                                <a
                                  href={pid.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {pid.file}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                      </TableRow>
                    )}
                    {/* History ===================================================== */}
                    <TableRow>
                      <TableCell colSpan={4} sx={{ background: "#f2f2f2" }}>
                        <strong>History</strong>
                      </TableCell>
                    </TableRow>

                    {/* Applied ===========================================================*/}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <div style={{ fontWeight: "bold" }}>Date Applied</div>
                        {/* <div>${data.dateFinaliseApproved}</div> */}
                        {format(
                          new Date(data.dateApplied.toDate()),
                          "EEE dd MMM yyyy hh:mm a"
                        )}
                      </TableCell>
                    </TableRow>

                    {/* Decline ===========================================================*/}
                    {isDeclined && hasRoles && (
                      <>
                        {/* recommendation */}

                        {/* decline */}
                        {data?.dateDeclined && (
                          <TableRow>
                            <TableCell colSpan={2}>
                              <div style={{ fontWeight: "bold" }}>
                                Date Declined
                              </div>
                              {/* <div>${data.dateFinaliseApproved}</div> */}
                              {data.dateDeclined &&
                                format(
                                  new Date(data?.dateDeclined.toDate()),
                                  "EEE dd MMM yyyy hh:mm a"
                                )}
                            </TableCell>

                            <TableCell colSpan={2}>
                              <div style={{ fontWeight: "bold" }}>
                                Decline Reason
                              </div>
                              <div>{toTitleCase(data.declineReason)}</div>
                            </TableCell>
                          </TableRow>
                        )}

                        {data?.declineOtherReason && (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <div style={{ fontWeight: "bold" }}>
                                Decline Other Reason
                              </div>
                              <div>{data.declineOtherReason}</div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}

                    {/* Recommended */}
                    {isRecommended && hasRoles && (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <div style={{ fontWeight: "bold" }}>
                            Date Recommended
                          </div>
                          {format(
                            new Date(data.dateRecommended.toDate()),
                            "EEE dd MMM yyyy hh:mm a"
                          )}
                        </TableCell>
                        <TableCell colSpan={2}>
                          <div style={{ fontWeight: "bold" }}>
                            Recommendation Comments
                          </div>
                          <div>{data.recommendation}</div>
                        </TableCell>
                      </TableRow>
                    )}

                    {/* Escalate ===========================================================*/}
                    {isEscalated && hasRoles && (
                      <>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <div style={{ fontWeight: "bold" }}>
                              Date Escalated
                            </div>
                            {format(
                              new Date(data.dateFinaliseEscalated.toDate()),
                              "EEE dd MMM yyyy hh:mm a"
                            )}
                          </TableCell>
                          <TableCell colSpan={2}>
                            <div style={{ fontWeight: "bold" }}>
                              Escalated To
                            </div>
                            <div>{data.escalatedTo}</div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={4}>
                            <div style={{ fontWeight: "bold" }}>
                              Escalate Comments
                            </div>
                            <div>{data.finaliseComments}</div>
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    {/* Finalise Approve, Decline, Escalate */}

                    {/* Approved ===========================================================*/}
                    {isApproved && hasRoles && (
                      <>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <div style={{ fontWeight: "bold" }}>
                              Date Approved
                            </div>

                            {/* approved directly by approver */}
                            {data.dateApproved && (
                              <>
                                {format(
                                  new Date(data.dateApproved.toDate()),
                                  "EEE dd MMM yyyy hh:mm a"
                                )}
                              </>
                            )}

                            {/* approved after escalation */}
                            {data.dateEscalatedToApproved && (
                              <>
                                {format(
                                  new Date(
                                    data.dateEscalatedToApproved.toDate()
                                  ),
                                  "EEE dd MMM yyyy hh:mm a"
                                )}
                              </>
                            )}
                          </TableCell>
                          {/* finaliseComments */}
                          <TableCell colSpan={2}>
                            <div style={{ fontWeight: "bold" }}>
                              Finalise Comments
                            </div>
                            {data.finaliseComments && (
                              <div>{data.finaliseComments}</div>
                            )}
                          </TableCell>
                        </TableRow>

                        {/* was escalated and Approved */}
                        {isEscalatedTo && (
                          <TableRow>
                            <TableCell colSpan={2}>
                              <div style={{ fontWeight: "bold" }}>
                                Escalated To Comments
                              </div>
                            </TableCell>
                            <TableCell colSpan={2}>
                              {data.esclatedToComments && (
                                <div>{data.esclatedToComments}</div>
                              )}
                              {data.escalatedToComments && (
                                <div>{data.escalatedToComments}</div>
                              )}
                            </TableCell>
                          </TableRow>
                        )}

                        {data.hocComments && (
                          <TableRow>
                            <TableCell colSpan={2}>
                              <div style={{ fontWeight: "bold" }}>
                                HOC Comments
                              </div>
                            </TableCell>
                            <TableCell colSpan={2}>
                              <div>{data.hocComments}</div>
                            </TableCell>
                          </TableRow>
                        )}

                        {data.escalatedToFinalComments && (
                          <TableRow>
                            <TableCell colSpan={2}>
                              <div style={{ fontWeight: "bold" }}>
                                CEO Comments
                              </div>
                            </TableCell>
                            <TableCell colSpan={2}>
                              <div>{data.escalatedToFinalComments}</div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}

                    {/* Declined ===========================================================*/}
                    {isDeclined && hasRoles && data?.dateFinaliseDeclined && (
                      <>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <div style={{ fontWeight: "bold" }}>
                              Date Finalise Declined
                            </div>
                            {data.dateFinaliseDeclined && (
                              <>
                                {" "}
                                {format(
                                  new Date(data.dateFinaliseDeclined.toDate()),
                                  "EEE dd MMM yyyy hh:mm a"
                                )}
                              </>
                            )}
                          </TableCell>
                          <TableCell colSpan={2}>
                            <div style={{ fontWeight: "bold" }}>
                              Finalise Comments
                            </div>
                            <div>{data.finaliseComments}</div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={4}>
                            <div style={{ fontWeight: "bold" }}>
                              Decline Email Reason
                            </div>
                            <div>{data.finaliseDeclineEmailMsg}</div>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>

            {/* Personal details  */}
            <TabPanel>
              <div style={{ textAlign: "left" }}>
                <p>
                  <strong>Personal Email:</strong> {data.email}
                </p>

                <p>
                  <strong>Phone:</strong> {data.mobile}
                </p>

                <p>
                  <strong>Date of Birth:</strong>{" "}
                  {format(new Date(data.dateOfBirth), "d MMM yyyy")}
                </p>
                <p>
                  <strong>Marital Status:</strong>{" "}
                  {toTitleCase(data.maritalStatus)}
                </p>
                <p>
                  <strong>Dependants:</strong> {data.dependants}
                </p>
                <p>
                  <strong>Postal Address:</strong>{" "}
                  {toTitleCase(data.postalAddress)}
                </p>
                <p>
                  <strong>Residential Address:</strong>{" "}
                  {toTitleCase(data.residentialAddress1)}
                </p>
                <p>
                  <strong>Duration of Stay:</strong>{" "}
                  {toTitleCase(data.durationOfStay1)}
                </p>
                <p>
                  <strong>Previous Residential Address:</strong>{" "}
                  {toTitleCase(data.residentialAddress2)}
                </p>
                <p>
                  <strong>Duration of Stay:</strong>{" "}
                  {toTitleCase(data.durationOfStay2)}
                </p>
              </div>
            </TabPanel>

            {/* expenses */}
            <TabPanel>
              <div style={{ textAlign: "left" }}>
                <p>
                  <strong>Rent:</strong> {USDollar.format(data.rent)}
                </p>
                <p>
                  <strong>Electricity:</strong>{" "}
                  {USDollar.format(data.electricity)}
                </p>
                <p>
                  <strong>Water:</strong> {USDollar.format(data.water)}
                </p>
                <p>
                  <strong>Mobile Spend:</strong>{" "}
                  {USDollar.format(data.mobileSpend)}
                </p>
                <p>
                  <strong>Groceries:</strong> {USDollar.format(data.groceries)}
                </p>
                <p>
                  <strong>School Fees:</strong>{" "}
                  {USDollar.format(data.schoolFees)}
                </p>
                <p>
                  <strong>Transport:</strong> {USDollar.format(data.travel)}
                </p>
                <p>
                  <strong>Subsciptions:</strong>{" "}
                  {USDollar.format(data.subscriptions)}
                </p>
                <p>
                  <strong>Entertainment:</strong>{" "}
                  {USDollar.format(data.entertainment)}
                </p>
                <p>
                  <strong>Insurance:</strong> {USDollar.format(data.insurance)}
                </p>
                <p>
                  <strong>Home Loans:</strong> {USDollar.format(data.homeLoans)}
                </p>
                <p>
                  <strong>Other Loans:</strong>{" "}
                  {USDollar.format(data.otherLoans)}
                </p>
                <p>
                  <strong>Hire Purchase:</strong>{" "}
                  {USDollar.format(data.hirePurchase)}
                </p>{" "}
                <p>
                  <strong>Other Expenses:</strong>{" "}
                  {USDollar.format(data.otherExpenses)}
                </p>{" "}
                <p>
                  <strong>Credit Cards:</strong>{" "}
                  {USDollar.format(data.creditCards)}
                </p>
              </div>
            </TabPanel>

            {/* bank details */}
            <TabPanel>
              <div style={{ textAlign: "left" }}>
                <p>
                  <strong>Bank:</strong> {toTitleCase(data.bank)}
                </p>
                <p>
                  <strong>Branch:</strong> {toTitleCase(data.branch)}
                </p>
                <p>
                  <strong>Account Name:</strong> {toTitleCase(data.accountName)}
                </p>
                <p>
                  <strong>Account Number:</strong>{" "}
                  {toTitleCase(data.accountNumber)}
                </p>
              </div>
            </TabPanel>

            {/* referees */}
            <TabPanel>
              {isCompliance && isApproved && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: ".5rem",
                    marginTop: ".5rem",
                  }}
                >
                  {/* extra info dialog button */}
                  <Button
                    sx={{
                      maxWidth: "250px",
                      textTransform: "capitalize",
                      backgroundColor: "#002a48",
                      borderRadius: "25px",
                      size: "small",
                    }}
                    variant="contained"
                    onClick={() => setOpenExtraInfo(true)}
                  >
                    Update Extra Info
                  </Button>
                </div>
              )}

              {/* <DisplayExtraInfo extraInfo={data.extra} /> */}
              <DisplayExtraInfo extraInfo={data} />
            </TabPanel>
          </Tabs>
        </Box>
      </Paper>

      {error && <div>{error}</div>}
    </div>
  );
};

export default ViewApplication;
