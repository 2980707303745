import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

import "./App.css";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Layout from "./components/Layout/Layout";
import FormStepper from "./components/FormStepper";
import Application from "./pages/application/Application";
import Signup from "./pages/signup/Signup";
import PreAssessment from "./components/assessment/PreAssessment";
import Claim from "./components/claim/Claim";
import EmailLinkSignIn from "./pages/emaillogin/EmailLinkSignIn";

// firebase imports
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { db, auth } from "./firebase/config";
// firebase imports
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";

import LoanApp from "./pages/application/LoanApp";
import NotFound from "./pages/notfound/NotFound";
import Search from "./pages/search/Search";
// import Applications from "./pages/search/test/Applications";
import Applications from "./pages/upl/Applications";
import Reports from "./pages/reports/Reports";
import Dashboard from "./pages/reports/Dashboard";
import Users from "./pages/users/Users";
import Updater from "./pages/reports/Updater";
// import useGetDoc from "./hooks/useGetDoc";

function App() {
  const { user, authIsReady, dispatch } = useAuthContext();
  const [hasRoles, setHasRoles] = useState(false);
  const [error, setError] = useState(null);
  // const { data: roles } = useGetDoc("users", user.uid);

  const navigate = useNavigate();

  useEffect(() => {
    const getRoles = async () => {
      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data().roles);
          if (docSnap.data().roles.length > 0) {
            setHasRoles(true);

            // console.log("docSnap.data()", docSnap.data());
          }
        } else {
          // console.log("No such document!");
        }
      } catch (error) {
        setError(error.code + ": " + error.message);
      }
    };

    if (user) getRoles();
  }, [user]);

  return (
    <div className="App">
      {authIsReady && (
        <section>
          <Layout>
            <Routes>
              <Route path="/" element={user ? <Home /> : <Login />} />
              <Route path="/login" element={<Login />} />

              <Route
                path="/new-upl-application"
                element={user ? <FormStepper /> : <Login />}
              />
              {/* duplicate a declined application */}
              <Route
                path="/new-upl-application/:id"
                element={user ? <FormStepper /> : <Login />}
              />
              <Route
                path="/upl-application/:id"
                element={user ? <LoanApp /> : <Login />}
              />
              <Route
                path="/assess/:id"
                element={hasRoles ? <PreAssessment /> : <NotFound />}
              />
              <Route
                path="/search"
                element={hasRoles ? <Search /> : <NotFound />}
              />
              <Route
                path="/dashboard"
                element={hasRoles ? <Dashboard /> : <NotFound />}
              />
              {/* ##### */}
              {/* <Route
                path="/runner"
                element={hasRoles ? <Updater /> : <NotFound />}
              /> */}
              <Route
                path="/users"
                element={hasRoles ? <Users /> : <NotFound />}
              />

              <Route path="/signup" element={<Signup />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
          {error && <p>{error}</p>}
        </section>
      )}
    </div>
  );
}

export default App;
