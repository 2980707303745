import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { RemoveFromQueue } from "@mui/icons-material";

import { db } from "../../firebase/config";
import { doc, updateDoc } from "firebase/firestore";

const ClosingDocsDialogue = (props) => {
  const { open, onClose, saveToJson, fName, oNames, lName, id } = props;
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [interestRate, setInterestRate] = useState("");
  const [repaymentMethod, setRepaymentMethod] = useState("Direct Deduction");
  const [installment, setInstallment] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("8848690");
  const [firstName, setFirstName] = useState(fName);
  const [otherNames, setOtherNames] = useState(oNames);
  const [lastName, setLastName] = useState(lName);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const bankOptions = [
    {
      accountNumber: "8848690",
      bank: "BSP",
    },
    {
      accountNumber: "22346400",
      bank: "WBC",
    },
    {
      accountNumber: "584904",
      bank: "ANZ",
    },
    {
      accountNumber: "000111722015",
      bank: "Bred Bank",
    },
    {
      accountNumber: "100012078",
      // accountNumber: "10704S10",
      bank: "HFC",
    },
  ];

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleInterestRateChange = (event) => {
    setInterestRate(event.target.value);
  };

  const saveStatus = async () => {
    setIsSaving(true);

    try {
      const docRef = doc(db, "unsecuredLoanApplications", id);
      const res = await updateDoc(docRef, {
        docStatus: "produced",
      });
      setIsSaving(false);
    } catch (err) {
      setError("Error saving document status: ", err.message);
      console.log("Error saving document status", err.message);
      setIsSaving(false);
    }
  };

  const handleSubmit = () => {
    // Perform any necessary actions with the start date and interest rate

    saveToJson(
      startDate,
      interestRate,
      repaymentMethod,
      installment,
      bankAccountNumber,
      firstName,
      otherNames,
      lastName
    );
    saveStatus();
    // Close the dialog
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Closing Documents Details</DialogTitle>
      <DialogContent>
        <form style={{}}>
          {/* firstName ============================================= */}
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="First Name"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
          />

          {/* otherNames ============================================= */}
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Other Names"
            type="text"
            value={otherNames}
            onChange={(e) => setOtherNames(e.target.value)}
            fullWidth
          />

          {/* lastName ============================================= */}
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Last Name"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
          />

          {/* payment start date ============================================= */}
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Repayment Start Date"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            fullWidth
          />

          {/* effective interest rate ============================================= */}
          <TextField
            margin="dense"
            size="small"
            label="Effective Interest Rate"
            type="number"
            value={interestRate}
            onChange={handleInterestRateChange}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              // readOnly: true,
            }}
            style={{ marginTop: "15px" }}
          />

          {/* payment method ============================================= */}
          <FormControl fullWidth required style={{ marginTop: "15px" }}>
            <InputLabel>Payment Method</InputLabel>
            <Select
              size="small"
              value={repaymentMethod}
              onChange={(e) => setRepaymentMethod(e.target.value)}
            >
              <MenuItem value="Direct Deduction" selected>
                Direct Deduction
              </MenuItem>
              <MenuItem value="Bank Authority">Bank Authority</MenuItem>
            </Select>
          </FormControl>

          {/* banks ============================================= */}
          <FormControl fullWidth required style={{ marginTop: "15px" }}>
            <InputLabel>MFL Repayment Bank</InputLabel>
            <Select
              size="small"
              value={bankAccountNumber}
              onChange={(e) => setBankAccountNumber(e.target.value)}
            >
              {bankOptions.map((bank) => (
                // <p key={uuidv4()}>{bank.accountNumber}</p>

                <MenuItem key={uuidv4()} value={bank.accountNumber}>
                  {bank.bank}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClosingDocsDialogue;
