import React from "react";

const Error = ({ err, module }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "red",
        padding: "20px",
        border: "1px solid red",
        borderRadius: "5px",
        backgroundColor: "#f2f2f2",
        width: "100%",
        textAlign: "center",
        marginTop: "1rem",
      }}
    >
      <span>Error Occured:</span>
      <span>{module}</span>
      <span>{err}</span>
    </div>
  );
};

export default Error;
