import styles from "./NumberInput.module.css";
import { useState, useEffect } from "react";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import LoanDetails from "./LoanDetails";
import { useFetcher } from "react-router-dom";

const INPUT_BACKGROUND = "#f2f2f2";

const Expenses = (props) => {
  const {
    repayAmt = "",
    data,
    setResult,
    setFinalExpenses,
    assessmentData,
  } = props;
  const [totalOutgoing, setTotalOutgoing] = useState("");
  const [surplus, setSurplus] = useState("");
  const [surplusAvailable, setSurplusAvailable] = useState("");
  const [netWeeklySurplus, setNetWeeklySurplus] = useState("");
  const [newRepayment, setNewRepayment] = useState("");
  const [dti, setDTI] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [expenses, setExpenses] = useState({
    rent: 0,
    electricity: 0,
    water: 0,
    mobile: 0,
    groceries: 0,
    schoolFees: 0,
    travel: 0,
    entertainment: 0,
    hirePurchase: 0,
    insurance: 0,
    otherExpenses: 0,
    creditCard: 0,
    otherLoans: 0,
  });

  const [netMonthly, setNetMonthly] = useState({
    netMonthly: 0,
    additionalIncome: 0,
    totalNetMonthly: 0,
  });

  useEffect(() => {
    setIsLoading(true);

    if (!assessmentData) {
      // =======================================================================================================
      // use the UPL application data

      setExpenses((prevState) => ({
        ...prevState,
        rent: parseFloat(data.rent).toFixed(2),
        electricity: parseFloat(data.electricity).toFixed(2),
        mobile: parseFloat(data.mobileSpend).toFixed(2),
        water: parseFloat(data.water).toFixed(2),
        groceries: parseFloat(data.groceries).toFixed(2),
        schoolFees: parseFloat(data.schoolFees).toFixed(2),
        travel: parseFloat(data.travel).toFixed(2),
        entertainment: parseFloat(data.entertainment).toFixed(2),
        hirePurchase: parseFloat(data.hirePurchase).toFixed(2),
        insurance: parseFloat(data.insurance).toFixed(2),
        subscriptions: parseFloat(data.subscriptions).toFixed(2),
        otherExpenses: parseFloat(data.otherExpenses).toFixed(2),
        creditCards: parseFloat(data.creditCards).toFixed(2),
        otherLoans: parseFloat(data.otherLoans).toFixed(2),
        homeLoans: parseFloat(data.homeLoans).toFixed(2),
      }));

      // test additional income, if not present, set to 0
      let addIncome = 0;
      if (data.additionalIncome) {
        addIncome = data.additionalIncome;
      }

      let tnm = Number(data.annualSalary) / 12 + Number(addIncome);

      setNetMonthly((prevState) => ({
        ...prevState,
        netMonthly: parseFloat(Number(data.annualSalary) / 12).toFixed(2),
        additionalIncome: parseFloat(Number(addIncome)).toFixed(2),
        totalNetMonthly: parseFloat(tnm).toFixed(2),
      }));
    } else {
      // =======================================================================================================
      // use the assessed data
      // Recommended, Escalated, Approved

      if (assessmentData) {
        setExpenses((prevState) => ({
          ...prevState,
          rent: parseFloat(assessmentData.rent).toFixed(2),
          electricity: parseFloat(assessmentData.electricity).toFixed(2),
          mobile: parseFloat(assessmentData.mobile).toFixed(2),
          water: parseFloat(assessmentData.water).toFixed(2),
          groceries: parseFloat(assessmentData.groceries).toFixed(2),
          schoolFees: parseFloat(assessmentData.schoolFees).toFixed(2),
          travel: parseFloat(assessmentData.travel).toFixed(2),
          entertainment: parseFloat(assessmentData.entertainment).toFixed(2),
          hirePurchase: parseFloat(assessmentData.hirePurchase).toFixed(2),
          insurance: parseFloat(assessmentData.insurance).toFixed(2),
          subscriptions: parseFloat(assessmentData.subscriptions).toFixed(2),
          otherExpenses: parseFloat(assessmentData.otherExpenses).toFixed(2),
          creditCards: parseFloat(assessmentData.creditCards).toFixed(2),
          otherLoans: parseFloat(assessmentData.otherLoans).toFixed(2),
          homeLoans: parseFloat(assessmentData.homeLoans).toFixed(2),
        }));

        let tnm =
          Number(assessmentData.netMonthly) +
          Number(assessmentData.additionalIncome);

        setNetMonthly((prevState) => ({
          ...prevState,
          netMonthly: assessmentData.netMonthly,
          additionalIncome: assessmentData.additionalIncome,
          totalNetMonthly: assessmentData.totalNetMonthly,
        }));
      }
    }
    handleExpenses();

    setIsLoading(false);
  }, [data, repayAmt, assessmentData]);

  const handleNetMonthlyChange = (e) => {
    const value = Number(e.target.value);
    const additionalIncome = Number(netMonthly.additionalIncome);

    const tnm = parseFloat(value + additionalIncome).toFixed(2);

    setNetMonthly((prev) => ({
      ...prev,
      netMonthly: value,
      totalNetMonthly: tnm,
    }));
  };

  const handleAdditionalIncomeChange = (e) => {
    const additionalIncome = Number(e.target.value);
    const nm = Number(netMonthly.netMonthly);

    const tnm = parseFloat(nm + additionalIncome).toFixed(2);

    setNetMonthly((prev) => ({
      ...prev,
      additionalIncome: additionalIncome,
      totalNetMonthly: tnm,
    }));
  };

  const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);

  const calcExpenses = () => {
    let totalExpenses =
      Number(expenses.rent) +
      Number(expenses.electricity) +
      Number(expenses.water) +
      Number(expenses.mobile) +
      Number(expenses.groceries) +
      Number(expenses.schoolFees) +
      Number(expenses.travel) +
      Number(expenses.subscriptions) +
      Number(expenses.entertainment) +
      Number(expenses.insurance) +
      Number(expenses.homeLoans) +
      Number(expenses.otherLoans) +
      Number(expenses.hirePurchase) +
      Number(expenses.otherExpenses) +
      Number(expenses.creditCards);

    setFinalExpenses({ ...expenses });

    return totalExpenses.toFixed(2);
  };

  const handleExpenses = () => {
    const _totalNetMonthly = netMonthly.totalNetMonthly;
    const _totalOutgoing = calcExpenses();
    const _surplus = parseFloat(_totalNetMonthly - _totalOutgoing).toFixed(2);
    const _surplusAvailable = parseFloat(_surplus - repayAmt).toFixed(2);
    const _netWeeklySurplus = parseFloat((_surplusAvailable * 12) / 52).toFixed(
      2
    );
    // console.log("totalNetMonthly", _totalNetMonthly);

    let _dti =
      parseFloat(
        parseFloat(repayAmt).toFixed(2) / parseFloat(_surplus).toFixed(2)
      ).toFixed(2) * 100;

    if (netMonthly.totalNetMonthly > 0) {
      setTotalOutgoing(_totalOutgoing);
      setSurplus(_surplus);
      setSurplusAvailable(_surplusAvailable);
      setNetWeeklySurplus(_netWeeklySurplus);
      setDTI(parseFloat(_dti).toFixed(0));

      setResult({
        totalOutgoing: _totalOutgoing,
        surplus: _surplus,
        newRepayment: repayAmt,
        surplusAvailable: _surplusAvailable,
        netWeeklySurplus: _netWeeklySurplus,
        dti: parseFloat(_dti).toFixed(0),
        netMonthly: netMonthly.netMonthly,
        additionalIncome: netMonthly.additionalIncome,
        totalNetMonthly: netMonthly.totalNetMonthly,
      });
    }
  };

  return (
    <div>
      {isLoading && <div>Loading data...</div>}
      <form>
        <Grid container rowSpacing={2} spacing={1} mt={1}>
          <Grid
            container
            rowSpacing={2}
            spacing={1}
            sx={{
              border: "1px solid red",
              padding: "0.5rem",
              margin: "0.5rem",
              borderRadius: "3px",
            }}
          >
            {/* Total Outgoing */}
            <Grid item xs={6} sm={4}>
              <TextField
                name="totalOutgoing"
                label="Total Outgoing"
                value={totalOutgoing}
                // onChange={(e) => setTotalOutgoing(e.targte.value)}
                onChange={(e) => setTotalOutgoing(e.target.value)}
                size="small"
                sx={{
                  "& input": {
                    textAlign: "center",
                    fontWeight: "bold",
                  },
                  backgroundColor: INPUT_BACKGROUND,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <TextField
                name="surplus"
                label="Surplus"
                value={surplus}
                // onChange={(e) => setSurplus(e.targte.value)}
                onChange={(e) => setSurplus(e.target.value)}
                size="small"
                sx={{
                  "& input": {
                    textAlign: "center",
                    fontWeight: "bold",
                  },
                  backgroundColor: INPUT_BACKGROUND,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <TextField
                name="newRepayment"
                label="New Repayment"
                value={repayAmt || 0}
                // onChange={(e) => setNewRepayment(e.targte.value)}
                onChange={(e) => setNewRepayment(e.target.value)}
                size="small"
                sx={{
                  "& input": {
                    textAlign: "center",
                    fontWeight: "bold",
                  },
                  backgroundColor: INPUT_BACKGROUND,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                name="surplusAvailable"
                label="Surplus Available"
                value={surplusAvailable}
                // onChange={(e) => setSurplusAvailable(e.targte.value)}
                onChange={(e) => setSurplusAvailable(e.target.value)}
                size="small"
                sx={{
                  "& input": {
                    textAlign: "center",
                    fontWeight: "bold",
                  },
                  backgroundColor: INPUT_BACKGROUND,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  readOnly: true,
                }}
                style={{ fontWeight: "bold" }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                name="netWeeklySurplus"
                label="Net Weekly Surplus"
                value={netWeeklySurplus}
                // onChange={(e) => setNetWeeklySurplus(e.targte.value)}
                onChange={(e) => setNetWeeklySurplus(e.target.value)}
                size="small"
                sx={{
                  "& input": {
                    textAlign: "center",
                    fontWeight: "bold",
                  },
                  backgroundColor: INPUT_BACKGROUND,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <TextField
                name="dti"
                label="Debt to Income Ratio (DTI)"
                size="small"
                value={dti}
                onChange={(e) => setDTI(e.target.value)}
                sx={{
                  "& input": {
                    textAlign: "center",
                    fontWeight: "bold",
                  },
                  backgroundColor: INPUT_BACKGROUND,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Net Monthly ===================================================== */}
          <Grid
            container
            rowSpacing={2}
            spacing={1}
            sx={{
              border: "1px solid red",
              padding: "0.5rem",
              margin: "0.5rem",
              borderRadius: "3px",
            }}
          >
            <Grid item xs={4}>
              <TextField
                name="netMonthly"
                label="Net Monthly"
                type="number"
                size="small"
                value={netMonthly.netMonthly}
                onChange={(e) => handleNetMonthlyChange(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  maxLength: 5,
                }}
                fullWidth
              />
            </Grid>

            {/* additional income */}
            <Grid item xs={4}>
              <TextField
                name="additionalIncome"
                label="Additional Income"
                type="number"
                size="small"
                value={netMonthly.additionalIncome}
                onChange={(e) => handleAdditionalIncomeChange(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            {/* total net monthly */}
            <Grid item xs={4}>
              <TextField
                // name="totalNetMonthly"
                label="Total Net Monthly"
                type="number"
                value={netMonthly.totalNetMonthly}
                // onChange={(e) =>
                //   setNetMonthly((prevState) => ({
                //     ...prevState,
                //     [e.target.name]: e.target.value,
                //   }))
                // }
                size="small"
                sx={{
                  "& input": {
                    textAlign: "center",
                    fontWeight: "bold",
                  },
                  backgroundColor: INPUT_BACKGROUND,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Expenses ============================================================================== */}
          <Grid
            container
            rowSpacing={2}
            spacing={1}
            sx={{
              border: "1px solid red",
              padding: "0.5rem",
              margin: "0.5rem",
              borderRadius: "3px",
            }}
          >
            {/* rent */}
            <Grid item xs={6} sm={3}>
              <TextField
                name="rent"
                label="Rent"
                type="number"
                size="small"
                value={expenses.rent}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    rent: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  maxLength: 5,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="electricity"
                label="Electricity"
                type="number"
                size="small"
                value={expenses.electricity}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    electricity: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  maxLength: 3,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="water"
                label="Water"
                type="number"
                size="small"
                value={expenses.water}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    water: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="mobile"
                label="Mobile/Internet"
                type="number"
                size="small"
                value={expenses.mobile}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    mobile: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="groceries"
                label="Groceries / Housekeeping"
                type="number"
                size="small"
                value={expenses.groceries}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    groceries: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="schoolFees"
                label="School Fees"
                type="number"
                size="small"
                value={expenses.schoolFees}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    schoolFees: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="travel"
                label="Travel"
                type="number"
                size="small"
                value={expenses.travel}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    travel: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                name="subscriptions"
                label="Subscriptions"
                type="number"
                size="small"
                value={expenses.subscriptions}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    subscriptions: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                name="entertainment"
                label="Entertainment"
                type="number"
                size="small"
                value={expenses.entertainment}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    entertainment: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="insurance"
                label="Life/Medical Insurance"
                type="number"
                size="small"
                value={expenses.insurance}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    insurance: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="homeLoans"
                label="Home Loans"
                type="number"
                size="small"
                value={expenses.homeLoans}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    homeLoans: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                name="otherLoans"
                label="Other Loans"
                type="number"
                size="small"
                value={expenses.otherLoans}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    otherLoans: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                name="hirePurchase"
                label="Hire Purchase"
                type="number"
                size="small"
                value={expenses.hirePurchase}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    hirePurchase: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="otherExpenses"
                label="Other Expenses"
                type="number"
                size="small"
                value={expenses.otherExpenses}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    otherExpenses: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                name="creditCards"
                label="Credit Card"
                type="number"
                size="small"
                value={expenses.creditCards}
                onChange={(e) => {
                  setExpenses((prev) => ({
                    ...prev,
                    creditCards: Number(e.target.value),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}></Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "0.5em",
                  marginBottom: "1em",
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleExpenses}
                  sx={{
                    width: "150px",
                    backgroundColor: "#002a48",
                    textTransform: "capitalize",
                    borderRadius: "25px",
                  }}
                >
                  Calculate
                </Button>
              </div>
            </Grid>
            {/* <Grid item sx={{ marginRight: "auto" }}></Grid> */}
          </Grid>
        </Grid>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "0.5em",
            marginBottom: "1em",
          }}
        >
          <Button
            type="button"
            variant="contained"
            onClick={handleExpenses}
            sx={{
              width: "150px",
              backgroundColor: "#002a48",
              textTransform: "capitalize",
              borderRadius: "25px",
            }}
          >
            Calculate
          </Button>
        </div> */}
      </form>
    </div>
  );
};

export default Expenses;
