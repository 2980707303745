import { Button, Grid, Paper } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Error from "../../components/error/Error";
import Textfield from "../../components/FormUI/Textfield";
import { useSignUp } from "../../hooks/useSignUp";

import * as Yup from "yup";

import styles from "./Signup.module.css";
import SubmitButton from "../../components/FormUI/SubmitButton";
import ActionButton from "../../components/FormUI/ActionButton";

export default function Signup() {
  const { error, signUp, isPending } = useSignUp();

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    displayName: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required!"),
    displayName: Yup.string()
      .min(3, "Display name is too short!")
      .max(12, "Display name is too long!")
      .required("Display name is required!"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters!")
      .required("Password is required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match!")
      .required("Confirming the password is required!"),
  });

  const handleSubmit = async (formValues) => {
    try {
      await signUp(
        formValues.email,
        formValues.password,
        formValues.displayName
      );
      navigate("/");
    } catch (err) {}
  };

  return (
    <div>
      <Paper className={styles.container}>
        <h2>Sign Up</h2>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {
            <Form className={styles.form}>
              <Grid container gap={2} flexDirection="column">
                {/* email */}
                <div>
                  <Grid item>
                    <Textfield label="Email" name="email" />
                  </Grid>
                </div>

                {/* password */}
                <div>
                  <Grid item>
                    <Textfield
                      type="password"
                      label="Password"
                      name="password"
                    />
                  </Grid>
                </div>

                {/* confirm password */}
                <div>
                  <Grid item>
                    <Textfield
                      type="password"
                      label="Confirm Password"
                      name="confirmPassword"
                    />
                  </Grid>
                </div>

                {/* display name */}
                <div>
                  <Grid item>
                    <Textfield label="Display Name" name="displayName" />
                  </Grid>
                </div>

                {/* action buttons */}
                <div>
                  <Grid item>
                    {!isPending && (
                      <SubmitButton name="submit">Sign Up</SubmitButton>
                      // <Button type="submit" variant="contained">
                      //   Sign Up
                      // </Button>
                    )}

                    {isPending && (
                      <ActionButton name="loading" disabled>
                        Loading
                      </ActionButton>

                      // <Button type="button" variant="contained" disabled>
                      //   Loading...
                      // </Button>
                    )}
                  </Grid>
                </div>
                <div>
                  <Grid item>
                    Already have an account? <Link to="/login">Sign In</Link>
                  </Grid>
                </div>

                {error && (
                  <div>
                    <Grid item>
                      <Error err={error} module="Sign Up" />
                    </Grid>
                  </div>
                )}
              </Grid>
            </Form>
          }
        </Formik>
      </Paper>
    </div>
  );
}
