import React from "react";

const NotFound = () => {
  return (
    <div>
      <h2>Wailei! Page not found.</h2>
      <p>Sorry, we couldn't find the page you were looking for.</p>
    </div>
  );
};

export default NotFound;
