import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// firebase imports
import { sendSignInLinkToEmail, isSignInWithEmailLink } from "firebase/auth";
import { db, auth } from "../../firebase/config";

function EmailLinkSignIn() {
  // state to hold email and email sent status
  const [email, setEmail] = useState("ravisa.daunivuka@mfl.com.fj");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const navigate = useNavigate();

  // function to send the sign-in email
  const sendSignInEmail = async (e) => {
    e.preventDefault();

    try {
      // send sign-in email to the user's email
      await sendSignInLinkToEmail(auth, email, {
        url: "http://localhost:3000/signin", // your app URL   http://localhost:3000/
        handleCodeInApp: true,
      });

      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem("emailForSignIn", email);

      // update email sent status
      setIsEmailSent(true);
    } catch (error) {
      console.error(error);
    }
  };

  // function to sign in with the email link
  const signInWithEmailLink = async () => {
    // confirm that the email link is valid
    // try {

    console.log(
      "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
    );

    if (isSignInWithEmailLink(auth, window.location.href)) {
      // get the email address from the link
      let email = window.localStorage.getItem("emailForSignIn");

      if (!email) {
        // if email is not available, prompt user to enter it
        email = window.prompt("Please provide your email for confirmation");
      }

      console.log("email", email);

      try {
        console.log("email before sign in", email);
        // sign in the user with the email link and the email address
        const result = await signInWithEmailLink(
          auth,
          email,
          window.location.href
        );
        // ####
        console.log("Successfully signed in!", result);

        // clear the email from local storage
        window.localStorage.removeItem("emailForSignIn");

        navigate("/new-upl-application");
      } catch (err) {
        console.log("signInWithEmailLink err", err.message);
        window.localStorage.removeItem("emailForSignIn");
      }
    }
  };

  useEffect(() => {
    // check if the page was loaded with an email link
    // signInWithEmailLink();
  }, []);

  return (
    <div>
      {!isEmailSent ? (
        <form onSubmit={sendSignInEmail}>
          <input
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit">Send sign-in email</button>
        </form>
      ) : (
        <p>A sign-in email has been sent to your email address.</p>
      )}
    </div>
  );
}

export default EmailLinkSignIn;

// // function to sign in with the email link
// const signInWithEmailLink = async () => {
//   // confirm that the email link is valid
//   try {
//     if (isSignInWithEmailLink(auth, window.location.href)) {
//       // get the email address from the link
//       let email = window.localStorage.getItem("emailForSignIn");

//       if (!email) {
//         // if email is not available, prompt user to enter it
//         email = window.prompt("Please provide your email for confirmation");
//       }

//       // sign in the user with the email link and the email address
//       const result = await signInWithEmailLink(
//         auth,
//         email,
//         window.location.href
//       );

//       // clear the email from local storage
//       window.localStorage.removeItem("emailForSignIn");

//       console.log(result);
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };
