import { useState, useEffect } from "react";

//firebase imports
import { db } from "../firebase/config";
import { doc, getDoc } from "firebase/firestore";

const useGetDoc = (collectionName, docId) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getDocument = async () => {
      try {
        setIsLoading(true);
        const docRef = doc(db, collectionName, docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setData(docSnap.data());
        }
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
      setIsLoading(false);
    };

    if (docId) {
      getDocument();
    }
  }, [collectionName, docId]);

  // return { data, isLoading, error };
  return { data, isLoading, error };
};

export default useGetDoc;
