import { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

//firebase imports
import { db, auth } from "../firebase/config";
// import { auth } from "../firebase/config";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export const useSignUp = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const { dispatch } = useAuthContext();

  const navigate = useNavigate();

  const signUp = async (email, password, displayName) => {
    setError(null);
    setIsPending(true);

    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);

      // update the user's display name in their profile
      const prof_res = await updateProfile(res.user, {
        displayName: displayName,
      });

      dispatch({ type: "LOGIN", payload: res.user });

      // console.log("Success, user signed up: ", res.user);

      // set the state
      if (!isCancelled) {
        setError(null);
        setIsPending(false);
      }

      // send them to the home page
      navigate("/");
    } catch (err) {
      console.log("Error Occured in useSignUp", err.message);

      // set the state
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
        console.log("Error Occured in useSignUp", err);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { error, signUp, isPending };
};
