import React from "react";
import { useField } from "formik";
import { ErrorMessage } from "formik";
import PropTypes from "prop-types";

const RadioInput = ({ label, options, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br></br>
      {options.map((option) => {
        return (
          <React.Fragment key={option.value}>
            <input
              type="radio"
              id={option.value}
              {...field}
              {...props}
              value={option.value}
              checked={field.value === option.value}
            />
            <label htmlFor={option.value}>{option.key}</label>
          </React.Fragment>
        );
      })}
      <ErrorMessage name={field.name}>
        {(msg) => (
          <div style={{ color: "#d32f2f" }}>
            <small>{msg}</small>
          </div>
        )}
      </ErrorMessage>
    </div>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default RadioInput;
