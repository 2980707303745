import { useState, useEffect } from "react";
import { TextField, InputAdornment, Grid, Button } from "@mui/material";
import Error from "../error/Error";

// firebase imports
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase/config";

import styles from "./NumberInput.module.css";
import { fr } from "date-fns/locale";

const INPUT_BACKGROUND = "#f2f2f2";

const LoanDetails = (props) => {
  const {
    setRepayAmt,
    data,
    setLoanDeets,
    setFinalRates,
    finalRates,
    fRates,
    assessmentData,
    surcharge,
    setSurcharge,
  } = props;
  const [loanDetails, setLoanDetails] = useState({
    loanAmount: 0,
    loanTerm: 0,
    establishmentFee: 0,
    insurance: 0,
    totalAmountFinanced: 0,
    totalInterest: 0,
    totalRepayable: 0,
    monthlyInstallment: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rates, setRates] = useState({
    interestRate: 0,
    insuranceRate: 0,
    estFeeRate: 0,
    surchargeRate: 0,
  });

  const [srchge, setSrchge] = useState({
    surcharge: 0,
    surchargeRate: 0,
  });

  const [totalMonthlyRepayment, setTotalMonthlyRepayment] = useState(0);

  const handleCalculate = (e = null) => {
    let _loanAmount = loanDetails.loanAmount;
    let _loanTerm = loanDetails.loanTerm;
    let _establishmentFee = loanDetails.establishmentFee;
    let _insurance = loanDetails.loanInsurance;
    let _totalAmountFinanced = loanDetails.totalAmountFinanced;
    let _totalInterest = loanDetails.totalInterest;
    let _totalRepayable = loanDetails.totalRepayable;
    let _monthlyInstallment = loanDetails.monthlyInstallment;

    let _interestRate = Number(rates.interestRate) / 100;
    let _insuranceRate = Number(rates.insuranceRate) / 100;
    let _establishmentFeeRate = Number(rates.estFeeRate) / 100;

    let _surchargeRate = Number(srchge.surchargeRate) / 100;
    let _surcharge = srchge.surcharge;

    // loan amount
    if (e) {
      if (e.target.name === "loanAmount") {
        setLoanDetails((prev) => ({
          ...prev,
          loanAmount: Number(e.target.value),
        }));

        _loanAmount = e.target.value;
      }
    }

    // loan term
    if (e) {
      if (e.target.name === "loanTerm") {
        setLoanDetails((prev) => ({
          ...prev,
          loanTerm: Number(e.target.value),
        }));

        _loanTerm = e.target.value;
      }
    }

    if (e) {
      if (e.target.name === "interestRate") {
        setRates((rates) => ({
          ...rates,
          [e.target.name]: e.target.value,
        }));
        _interestRate = Number(e.target.value) / 100;
      }
    }

    if (e) {
      if (e.target.name === "estFeeRate") {
        _establishmentFeeRate = Number(e.target.value) / 100;

        setRates((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
      }
    }

    if (e) {
      if (e.target.name === "insuranceRate") {
        setRates((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
        _insuranceRate = Number(e.target.value) / 100;
      }
    }

    if (e) {
      if (e.target.name === "surchargeRate") {
        setSrchge((prev) => ({
          ...prev,
          surchargeRate: e.target.value,
        }));
        setRates((rates) => ({
          ...rates,
          [e.target.name]: e.target.value,
        }));

        _surchargeRate = Number(e.target.value) / 100;
      }
    }

    // account for declined
    if (e === null) {
      if (!assessmentData) {
        _loanAmount = data.loanAmount;
        _loanTerm = data.loanTerm;
      } else {
        _loanAmount = assessmentData.loanAmount;
        _loanTerm = assessmentData.loanTerm;
      }
    }

    // set the finalRates
    setFinalRates({
      interestRate: parseFloat(Number(_interestRate) * 100).toFixed(2),
      insuranceRate: parseFloat(Number(_insuranceRate) * 100).toFixed(2),
      estFeeRate: parseFloat(Number(_establishmentFeeRate) * 100).toFixed(2),
      surchargeRate: parseFloat(Number(_surchargeRate) * 100).toFixed(2),
    });

    _insurance = parseFloat(
      parseFloat(_loanAmount) *
        parseFloat(_insuranceRate) *
        (parseFloat(_loanTerm) / 12)
    ).toFixed(2);

    _establishmentFee = parseFloat(_loanAmount * _establishmentFeeRate).toFixed(
      2
    );

    _totalAmountFinanced = parseFloat(
      parseFloat(_loanAmount) +
        parseFloat(_insurance) +
        parseFloat(_establishmentFee)
    ).toFixed(2);

    _totalInterest = parseFloat(
      _totalAmountFinanced * _interestRate * (_loanTerm / 12)
    ).toFixed(2);

    _totalRepayable = parseFloat(
      parseFloat(_loanAmount) +
        parseFloat(_insurance) +
        parseFloat(_establishmentFee) +
        parseFloat(_totalInterest)
    ).toFixed(2);

    _monthlyInstallment =
      parseFloat(
        parseFloat(_loanAmount) +
          parseFloat(_insurance) +
          parseFloat(_establishmentFee) +
          parseFloat(_totalInterest)
      ) / parseFloat(_loanTerm);

    if (_surchargeRate >= 0) {
      _surcharge = parseFloat(
        parseFloat(_surchargeRate) * _monthlyInstallment
      ).toFixed(2);

      setSrchge((prev) => ({
        ...prev,
        surcharge: _surcharge,
      }));

      setSurcharge({
        surcharge: _surcharge,
        surchargeRate: Number(_surchargeRate) * 100,
      });
    }

    let x = parseFloat(parseFloat(_surcharge) + _monthlyInstallment).toFixed(2);
    setTotalMonthlyRepayment(x);

    if (_loanAmount > 0 && _loanTerm > 0) {
      if (_monthlyInstallment > 0) {
        setRepayAmt(parseFloat(_monthlyInstallment).toFixed(2));
      }
    }

    if (_loanAmount > 0 && _loanTerm > 0) {
      setLoanDetails((prevState) => ({
        ...prevState,
        loanAmount: _loanAmount,
        loanTerm: _loanTerm,
        establishmentFee: _establishmentFee,
        loanInsurance: _insurance,
        totalAmountFinanced: _totalAmountFinanced,
        totalInterest: _totalInterest,
        totalRepayable: _totalRepayable,
        monthlyInstallment: _monthlyInstallment.toFixed(2),
      }));

      setLoanDeets({
        loanAmount: _loanAmount,
        loanTerm: _loanTerm,
        monthlyInstallment: _monthlyInstallment.toFixed(2),
        estFee: _establishmentFee,
        loanInsurance: _insurance,
        totalAmountFinanced: _totalAmountFinanced,
        totalInterest: _totalInterest,
        totalRepayable: _totalRepayable,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (fRates !== null) {
      setRates({
        interestRate: parseFloat(fRates[0].interestRate).toFixed(2),
        insuranceRate: parseFloat(fRates[0].insuranceRate).toFixed(2),
        estFeeRate: parseFloat(fRates[0].estFeeRate).toFixed(2),
        surchargeRate: parseFloat(fRates[0].surchargeRate).toFixed(2),
      });
    }

    setLoanDetails((prevState) => ({
      ...prevState,
      loanAmount: data.loanAmount,
      loanTerm: data.loanTerm,
    }));

    if (assessmentData) {
      setLoanDetails((prevState) => ({
        ...prevState,
        loanAmount: assessmentData.loanAmount,
        loanTerm: assessmentData.loanTerm,
      }));

      setRates({
        interestRate: parseFloat(assessmentData.interestRate).toFixed(2),
        insuranceRate: parseFloat(assessmentData.insuranceRate).toFixed(2),
        estFeeRate: parseFloat(assessmentData.estFeeRate).toFixed(2),
        surchargeRate: parseFloat(assessmentData.surchargeRate).toFixed(2),
      });

      setSrchge((prevState) => ({
        ...prevState,
        surchargeRate: parseFloat(assessmentData.surchargeRate).toFixed(2),
      }));
    }

    handleCalculate();
  }, [data, assessmentData]);

  useEffect(() => {
    handleCalculate();
  }, [rates]);

  return (
    <div>
      {!isLoading && (
        <form>
          <Grid container rowSpacing={2} spacing={1} mt={1}>
            <Grid
              container
              rowSpacing={2}
              spacing={1}
              sx={{
                border: "1px solid red",
                padding: "0.5rem",
                margin: "0.5rem",
                borderRadius: "3px",
              }}
            >
              {/* interestRate */}
              <Grid item xs={6}>
                <TextField
                  label="Interest Rate"
                  type="number"
                  size="small"
                  name="interestRate"
                  value={rates.interestRate}
                  onChange={(e) => {
                    setRates((rates) => ({
                      ...rates,
                      [e.target.name]: e.target.value,
                    }));
                    handleCalculate(e);
                  }}
                  sx={{
                    "& input": {
                      textAlign: "center",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>

              {/* estFeeRate */}
              <Grid item xs={6}>
                <TextField
                  label="Est. Fee Rate"
                  type="number"
                  size="small"
                  name="estFeeRate"
                  value={rates.estFeeRate}
                  // onChange={(e) => {
                  //   handleCalculate(e);
                  // }}
                  onChange={(e) => {
                    setRates((rates) => ({
                      ...rates,
                      [e.target.name]: e.target.value,
                    }));
                    handleCalculate(e);
                  }}
                  sx={{
                    "& input": {
                      textAlign: "center",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>

              {/* insuranceRate */}
              <Grid item xs={6}>
                <TextField
                  label="Insurance Rate"
                  type="number"
                  size="small"
                  name="insuranceRate"
                  value={rates.insuranceRate}
                  onChange={(e) => {
                    setRates((rates) => ({
                      ...rates,
                      [e.target.name]: e.target.value,
                    }));
                    handleCalculate(e);
                  }}
                  sx={{
                    "& input": {
                      textAlign: "center",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>

              {/* surchargeRate */}
              <Grid item xs={6}>
                <TextField
                  label="Surcharge Rate"
                  type="number"
                  size="small"
                  name="surchargeRate"
                  value={srchge.surchargeRate}
                  onChange={(e) => {
                    setSrchge((prev) => ({
                      ...prev,
                      surchargeRate: e.target.value,
                    }));
                    setRates((rates) => ({
                      ...rates,
                      [e.target.name]: e.target.value,
                    }));
                    handleCalculate(e);
                  }}
                  sx={{
                    "& input": {
                      textAlign: "center",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  // onClick={handleRates}
                  onClick={handleCalculate}
                  sx={{
                    width: "150px",
                    backgroundColor: "#002a48",
                    textTransform: "capitalize",
                    borderRadius: "25px",
                  }}
                >
                  Calculate
                </Button>
              </Grid>
            </Grid>

            {/* loan amount & term =========================================================================== */}
            <Grid
              container
              rowSpacing={2}
              spacing={1}
              sx={{
                border: "1px solid red",
                padding: "0.5rem",
                margin: "0.5rem",
                borderRadius: "3px",
              }}
            >
              {/* loanAmount */}
              <Grid item xs={6}>
                <TextField
                  name="loanAmount"
                  label="Loan Amount"
                  type="number"
                  size="small"
                  value={loanDetails.loanAmount}
                  onChange={(e) => handleCalculate(e)}
                  // onChange={handleLoanAmountChange}
                  sx={{
                    "& input": {
                      textAlign: "center",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    maxLength: 5,
                  }}
                  fullWidth
                />
              </Grid>

              {/* loanTerm */}
              <Grid item xs={6}>
                <TextField
                  name="loanTerm"
                  label="Loan Term (months)"
                  type="number"
                  size="small"
                  maxLength={2}
                  value={loanDetails.loanTerm}
                  onChange={(e) => handleCalculate(e)}
                  sx={{
                    "& input": {
                      textAlign: "center",
                    },
                  }}
                  InputProps={{
                    maxLength: 2,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            {/* End of loan amount & term =========================================================================== */}

            <Grid
              container
              rowSpacing={2}
              spacing={1}
              sx={{
                border: "1px solid red",
                padding: "0.5rem",
                margin: "0.5rem",
                borderRadius: "3px",
              }}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  name="monthlyInstallment"
                  label="Monthly Repayment"
                  value={loanDetails.monthlyInstallment}
                  // onChange={(e) =>
                  //   setLoanDetails((prevState) => ({
                  //     ...prevState,
                  //     [e.target.name]: e.target.value,
                  //   }))
                  // }
                  size="small"
                  sx={{
                    backgroundColor: INPUT_BACKGROUND,
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="establishmentFee"
                  label="Establishment Fee"
                  value={loanDetails.establishmentFee}
                  // onChange={(e) =>
                  //   setLoanDetails((prevState) => ({
                  //     ...prevState,
                  //     [e.target.name]: e.target.value,
                  //   }))
                  // }
                  size="small"
                  sx={{
                    backgroundColor: INPUT_BACKGROUND,
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="insurance"
                  label="Insurance"
                  value={loanDetails.loanInsurance}
                  // onChange={(e) =>
                  //   setLoanDetails((prevState) => ({
                  //     ...prevState,
                  //     [e.target.name]: e.target.value,
                  //   }))
                  // }
                  size="small"
                  sx={{
                    backgroundColor: INPUT_BACKGROUND,
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="totalAmountFinanced"
                  label="Total Amount Financed"
                  value={loanDetails.totalAmountFinanced}
                  // onChange={(e) =>
                  //   setLoanDetails((prevState) => ({
                  //     ...prevState,
                  //     [e.target.name]: e.target.value,
                  //   }))
                  // }
                  size="small"
                  sx={{
                    backgroundColor: INPUT_BACKGROUND,
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="totalInterest"
                  label="Total Interest"
                  value={loanDetails.totalInterest}
                  // onChange={(e) =>
                  //   setLoanDetails((prevState) => ({
                  //     ...prevState,
                  //     [e.target.name]: e.target.value,
                  //   }))
                  // }
                  size="small"
                  sx={{
                    backgroundColor: INPUT_BACKGROUND,
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="totalRepayable"
                  label="Total Repayable"
                  value={loanDetails.totalRepayable}
                  // onChange={(e) =>
                  //   setLoanDetails((prevState) => ({
                  //     ...prevState,
                  //     [e.target.name]: e.target.value,
                  //   }))
                  // }
                  size="small"
                  sx={{
                    backgroundColor: INPUT_BACKGROUND,
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              {/* surcharge */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="surcharge"
                  label="Surcharge"
                  value={srchge.surcharge}
                  size="small"
                  sx={{
                    backgroundColor: INPUT_BACKGROUND,
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              {/* totalMonthlyRepayment */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="totalMonthlyRepayment"
                  label="Total Monthly Repayment"
                  value={totalMonthlyRepayment}
                  size="small"
                  sx={{
                    backgroundColor: INPUT_BACKGROUND,
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {error && <Error err={error} module="Loan Details" />}
    </div>
  );
};

export default LoanDetails;
