import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";

// firebase imports
import { db } from "../../firebase/config";
import {
  getDocs,
  collection,
  orderBy,
  query,
  documentId,
  where,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";

import Reports from "./Reports";
import ReportCard from "./ReportCard";
import { Grid } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import Error from "../../components/error/Error";

const UPL_COLLECTION = "unsecuredLoanApplications";
const ASSESSED_COLLECTION = "assessedUPL";

const Dashboard = () => {
  const [error, setError] = useState(null);
  const [reportData, setReportData] = useState([]);

  const [data, setData] = useState([]);
  const [approvedAppsIds, setApprovedAppsIds] = useState([]);
  const [approvedAppId, setApprovedAppId] = useState("");
  const [approvedAppsData, setApprovedAppsData] = useState([]);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  let statuses = [
    "Submitted",
    "Assessing",
    "Recommended",
    "Escalated",
    "Declined",
    "Approved",
  ];
  // status: approved
  let statusData = [];
  let statusCount = 0;
  let statusSum = 0;
  let approvedSum = 0;
  let kpiData = [];
  let approvedApps = [];
  let approvedAmounts = [];
  let finalisedApps = [];
  let resData = [];
  let allStatusData = [];

  // ----------------------------------------------
  // set up the KPIs
  const setUpTheKPIs = (appData) => {
    kpiData = [];

    //iterate over statuses
    statuses.map((status) => {
      statusData = appData.filter((item) => item.status === status);

      statusSum = statusData.reduce((total, item) => {
        return total + item.loanAmount;
      }, 0);

      statusCount = statusData.length;

      // ----------------------------------------------
      // aged days
      let maxDate = new Date();

      // submitted
      if (status === "Submitted") {
        // work out the aged days for dateApplied
        maxDate = new Date(
          Math.min(
            ...statusData.map((element) => {
              return new Date(element.dateApplied);
            })
          )
        );
      }
      // assessing
      else if (status === "Assessing") {
        // work out the aged days for dateAssessed
        maxDate = new Date(
          Math.min(
            ...statusData.map((element) => {
              return new Date(element.dateAssessed);
            })
          )
        );
      }
      // recommended
      else if (status === "Recommended") {
        // work out the aged days for dateRecommended
        maxDate = new Date(
          Math.min(
            ...statusData.map((element) => {
              return new Date(element.dateRecommended);
            })
          )
        );
      }
      // escalated
      else if (status === "Escalated") {
        // work out the aged days for dateRecommended
        maxDate = new Date(
          Math.min(
            ...statusData.map((element) => {
              return new Date(element.dateEscalated);
            })
          )
        );
        // ####
        // console.log("escalated", statusData);
      }

      let agedDays = 0;
      agedDays = Math.round((new Date() - maxDate) / (1000 * 3600 * 24));
      if (agedDays < 0) agedDays = 0;

      // ----------------------------------------------
      //add object to the reportData object array
      if (status !== "Approved") {
        kpiData.push({
          status: status,
          total: statusSum,
          count: statusCount,
          age: agedDays,
        });
      }

      if (status === "Approved") {
        kpiData.push({
          status: status,
          total: statusSum,
          count: statusCount,
          age: agedDays,
          approvedSum: approvedSum,
        });
      }
    });

    setReportData(kpiData);
  };

  const getDashboardData = async (reportMonth) => {
    let start = reportMonth + "-01";
    // add 1 month to start date
    let end = format(
      new Date(new Date(start).setMonth(new Date(start).getMonth() + 1)),
      "yyyy-MM-dd"
    );
    let q = "";

    // --------------------------------------------------------------------
    // get the applications which ARE approved or declined
    try {
      const uplRef = collection(db, UPL_COLLECTION);
      q = query(
        uplRef,
        where("dateApplied", ">=", new Date(start)),
        where("dateApplied", "<", new Date(end)),
        where("status", "in", ["Declined", "Approved"]), // newly added to account for non approved apps
        orderBy("dateApplied", "desc"),
        orderBy("firstName", "asc")
      );

      // execute the query
      const res = await getDocs(q);

      finalisedApps = res.docs.map((doc) => ({
        id: doc.id,
        loanAmount: doc.data().loanAmount,
        status: doc.data().status,
        dateApplied: doc.data().dateApplied
          ? format(doc.data().dateApplied.toDate(), "yyyy-MM-dd")
          : null,
        dateAssessed: doc.data().dateAssessed
          ? format(doc.data().dateAssessed.toDate(), "yyyy-MM-dd")
          : null,
        dateRecommended: doc.data().dateRecommended
          ? format(doc.data().dateRecommended.toDate(), "yyyy-MM-dd")
          : null,
        dateApproved: doc.data().dateApproved
          ? format(doc.data().dateApproved.toDate(), "yyyy-MM-dd")
          : null,
        dateDeclined: doc.data().dateDeclined
          ? format(doc.data().dateDeclined.toDate(), "yyyy-MM-dd")
          : null,
      }));

      // filter res for approved applications and get the dateApproved and id only
      let approvedFilteredApps = finalisedApps.filter(
        (app) => app["status"] === "Approved"
      );

      approvedFilteredApps = approvedFilteredApps.map((app) => ({
        id: app.id,
        dateApproved: Timestamp.fromDate(new Date(app.dateApproved)),
      }));

      // filter apps to only approved applications
      approvedApps = finalisedApps.filter(
        (app) => app["status"] === "Approved"
      );

      //extract the ids of the approved applications
      approvedApps = approvedApps.map((app) => app.id);
    } catch (err) {
      setError("Error getting data: " + err.message);
    }

    // --------------------------------------------------------------------
    // get the applications which ARE NOT approved or declined
    try {
      const uplRef = collection(db, UPL_COLLECTION);
      q = query(
        uplRef,
        where("status", "in", [
          "Submitted",
          "Assessing",
          "Recommended",
          "Escalated",
          // "Declined",
        ]), // newly added to account for non approved apps
        orderBy("dateApplied", "desc"),
        orderBy("firstName", "asc")
      );

      // execute the query
      const res = await getDocs(q);

      resData = res.docs.map((doc) => ({
        id: doc.id,
        loanAmount: doc.data().loanAmount,
        status: doc.data().status,
        dateApplied: doc.data().dateApplied
          ? format(doc.data().dateApplied.toDate(), "yyyy-MM-dd")
          : null,
        dateAssessed: doc.data().dateAssessed
          ? format(doc.data().dateAssessed.toDate(), "yyyy-MM-dd")
          : null,
        dateRecommended: doc.data().dateRecommended
          ? format(doc.data().dateRecommended.toDate(), "yyyy-MM-dd")
          : null,
        dateApproved: doc.data().dateApproved
          ? format(doc.data().dateApproved.toDate(), "yyyy-MM-dd")
          : null,
        dateDeclined: doc.data().dateDeclined
          ? format(doc.data().dateDeclined.toDate(), "yyyy-MM-dd")
          : null,
        dateEscalated: doc.data().dateFinaliseEscalated
          ? format(doc.data().dateFinaliseEscalated.toDate(), "yyyy-MM-dd")
          : null,
      }));

      allStatusData = finalisedApps.concat(resData);
    } catch (err) {
      setError("Error getting data: " + err.message);
    }

    // --------------------------------------------------------------------
    // get the approved assessed apps data
    if (approvedApps.length <= 0) {
      return;
    }

    try {
      const uplRef = collection(db, ASSESSED_COLLECTION);
      // add filter by dateApproved
      q = query(
        uplRef,
        where("status", "==", "Approved"),
        where("dateSaved", ">=", new Date(start)),
        where("dateSaved", "<", new Date(end)),
        orderBy("dateSaved", "desc")
      );

      // execute the query
      const result = await getDocs(q);

      approvedAmounts = result.docs.map((doc) => ({
        id: doc.id,
        "Approved Loan Amount": doc.data().loanAmount,
      }));

      approvedSum = approvedAmounts.reduce((total, item) => {
        return (
          Number(total) +
          Number(parseFloat(item["Approved Loan Amount"]).toFixed(2))
        );
      }, 0);

      // set up the KPIs
      if (allStatusData.length > 0) setUpTheKPIs(allStatusData);
    } catch (err) {
      setError("Error getting data: " + err.message);
    }
  };

  useEffect(() => {
    let start = format(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      "yyyy-MM"
    );
    getDashboardData(start);
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        {/* set up applications report download */}
        <Grid item xs={12}>
          <Reports />
        </Grid>

        {/* set up applications dashboard */}
        <Grid item xs={12}>
          {reportData && <ReportCard reportData={reportData} />}
        </Grid>
      </Grid>

      {/* error display */}
      {error && <Error err={error} module={"Dashboard"} />}
    </div>
  );
};

export default Dashboard;
