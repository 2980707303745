import React, { useState, useEffect } from "react";
import { Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { useAuthContext } from "../../hooks/useAuthContext";

import styles from "./ApplicationList.module.css";

import ActionButton from "../../components/FormUI/ActionButton";

const ApplicationList = ({ uplApps, isAssessor }) => {
  const navigate = useNavigate();
  const [dateApplied, setDateApplied] = useState(null);
  const { user } = useAuthContext();
  const [error, setError] = useState(null);
  const [isApps, setIsApps] = useState(false);

  useEffect(() => {
    if (uplApps)
      try {
        if (uplApps.dateApplied) {
          setDateApplied(format(parseISO(uplApps.dateApplied), "do MMMM Y"));
        }
      } catch (err) {
        setError(err.message);
      }
    if (uplApps.length > 0) {
      setIsApps(true);
    }
  }, [uplApps, isApps]);

  const handleCopyApplication = (Id) => {
    navigate(`/new-upl-application/${Id}`);
  };

  return (
    <section className={styles.container}>
      <h2>UPL Applications</h2>
      <Grid container spacing={1}>
        {uplApps &&
          uplApps.map((app) => (
            <Grid item xs={12} sm={6} sm={4} key={app.id}>
              <Paper elevation={4} m={2} sx={{ m: 2 }}>
                <div className="card">
                  <div className={styles.card_header}>
                    <div className={styles.card_title}>
                      <div>
                        <b>
                          {/* navigate */}
                          <Link to={`/upl-application/${app.id}`}>
                            {" "}
                            {app.firstName} {app.otherNames} {app.lastName}
                          </Link>
                        </b>
                      </div>
                    </div>
                    {app.employer}
                    <br />
                    <br />
                    <Divider variant="middle" />
                  </div>
                  <div className={styles.card_body}>
                    <div>{app.mobile}</div>
                    <div>{app.email}</div>
                    <div>Loan Amount: ${app.loanAmount}</div>
                    <div>
                      Date Applied:
                      {format(
                        new Date(app.appliedAt.seconds * 1000),
                        "EEE dd MMM yy hh:mm a"
                      )}
                    </div>
                    {app.assessor ? (
                      <div>Assesor: {app.assessor.displayName || ""} </div>
                    ) : (
                      <div>Assesor: </div>
                    )}
                    <div>Application Status:</div>
                    <div className={styles.card_chip}>
                      <Chip
                        variant="outlined"
                        label={app.status ? app.status : "dev"}
                        sx={{
                          backgroundColor: "#74ddf3",
                          borderColor: "#74ddf3",
                        }}
                      />
                    </div>

                    {app.status === "Declined" && (
                      <div className={styles.action}>
                        <ActionButton
                          onClick={() => handleCopyApplication(app.id)}
                        >
                          Copy Application
                        </ActionButton>
                      </div>
                    )}
                  </div>
                </div>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </section>
  );
};

export default ApplicationList;
