import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

import React from "react";

const CheckboxWrapper = ({ name, label, legend, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };

  const configCheckbox = {
    ...field,
    ...otherProps,
    onChange: handleChange,
  };

  const configFormControl = {};
  // if (meta && meta.touched && meta.error) {
  //   configFormControl.error = true;
  // }
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    configFormControl.helperText = meta.error;
  }
  return (
    <FormControl {...configFormControl}>
      {legend ? (
        <FormLabel
          component={"legend"}
          style={{ textAlign: "left", marginBottom: "0" }}
        >
          <strong>{legend}</strong>
        </FormLabel>
      ) : null}
      <FormGroup>
        <FormControlLabel
          style={{ textAlign: "left", marginBottom: "0" }}
          control={<Checkbox {...configCheckbox} />}
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxWrapper;
