import { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

//firebase imports
import { auth, db } from "../firebase/config";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const useLogIn = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const { dispatch } = useAuthContext();

  const logIn = async (email, password) => {
    setError(null);
    setIsPending(true);

    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      // console.log(res);

      // add user to Users collection
      // check whether the user exist in users table
      const userRef = doc(db, "users", res.user.uid);
      const userDocSnap = await getDoc(userRef);
      if (!userDocSnap.exists()) {
        // add the user to the users collection
        const user_res = await setDoc(doc(db, "users", res.user.uid), {
          // displayName: simpleDisplayName[0],
          roles: [],
        });
      } else {
        // console.log("docSnap.data()", userDocSnap.data());
      }

      // uid, displayName, email, groups[]
      dispatch({ type: "LOGIN", payload: res.user });

      // set the state
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      // set the state
      if (!isCancelled) {
        setIsPending(false);
        setError(err.message);
        console.log("Login Error", err.message);
      }
      console.log("Login Error", err.message);
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { error, logIn, isPending };
};
