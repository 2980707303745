import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// firebase imports
import { db } from "../../firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import { useAuthContext } from "../../hooks/useAuthContext";

import TextError from "../../components/FormUI/TextError";
import ViewApplication from "./ViewApplication";

import useGetDoc from "../../hooks/useGetDoc";
import CustomerDocs from "../customerDocs/CustomerDocs";

const UPL_COLLECTION = "unsecuredLoanApplications";

const LoanApp = () => {
  const { id } = useParams();
  const { data: assessmentData, error: assError } = useGetDoc(
    "assessedUPL",
    id
  );

  const { user } = useAuthContext();
  //get the roles associated with the current user
  const { data: userData } = useGetDoc("users", user.uid);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [openExtraInfo, setOpenExtraInfo] = useState(false);
  const [showCustomerDocs, setshowCustomerDocs] = useState(false);

  const notifyUser = (notification) => {
    toast.info(notification, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const getApplication = async () => {
    try {
      const appRef = doc(db, UPL_COLLECTION, id);
      const appSnap = await getDoc(appRef);
      if (appSnap.exists()) {
        setData(appSnap.data());
      }
    } catch {
      setError("Error fetching application: " + error.message);
    }
  };

  // func to update extra info
  const updateApp = async (values) => {
    // create object extraInfo and add the values as a nested object
    const extraInfo = {
      referees: {
        ...values,
      },
      repaymentMode: values.repaymentMode,
    };
    // remove repaymentMode from referees, it now exist on it's own
    delete extraInfo.referees.repaymentMode;

    try {
      //update the assessed apps
      const assessedRef = doc(db, UPL_COLLECTION, id);
      await updateDoc(assessedRef, {
        ...extraInfo,
      });

      // call reload of data from db
      getApplication();
      setOpenExtraInfo(false);

      notifyUser("The extra information has been saved successfully!");
    } catch (error) {
      setError("Error updating extra information: " + error.message);
    }
  };

  useEffect(() => {
    getApplication();
    if (userData && userData.roles.includes("assessor")) {
      if (data.status === "Assessing" || data.status === "Recommended")
        setshowCustomerDocs(true);
    }

    // ####
    console.log("userData", userData);
  }, [id, userData]);

  return (
    <div>
      {error ? <TextError err={error} /> : null}
      {assError ? <TextError err={assError} /> : null}

      {data ? (
        <ViewApplication
          data={data}
          assessmentData={assessmentData}
          updateApp={updateApp}
          openExtraInfo={openExtraInfo}
          setOpenExtraInfo={setOpenExtraInfo}
          showCustomerDocs={showCustomerDocs}
          getApplication={getApplication}
        />
      ) : (
        <p>No data to display</p>
      )}

      <ToastContainer />
    </div>
  );
};

export default LoanApp;
