import React, { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

//firebase imports
import { db, auth, googleProvider } from "../firebase/config";
import { signInWithPopup, updateProfile } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";

export const useSignInWithGoogle = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [simpleDisplayName, setSimpleDisplayName] = useState("");
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    setError(null);
    setIsPending(true);

    try {
      const res = await signInWithPopup(auth, googleProvider);

      let simpleDisplayName = res.user.displayName;

      // update the user's display name in their profile
      const prof_res = await updateProfile(res.user, {
        displayName: simpleDisplayName[0],
      });

      dispatch({ type: "LOGIN", payload: res.user });

      // set the state
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }

      // send them to the home page
      navigate("/");
    } catch (err) {
      // set the state

      setIsPending(false);
      setError(err.message);

      if (!isCancelled) {
        setIsPending(false);
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { error, isPending, signInWithGoogle };
};

export default useSignInWithGoogle;
