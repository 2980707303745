import React, { useEffect, useState } from "react";
import Textfield from "../components/FormUI/Textfield";
import ButtonWrapper from "../components/FormUI/ButtonWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styles from "./Refs.module.css";
import { Button, Grid, Paper } from "@mui/material";
import SubmitButton from "./FormUI/SubmitButton";
import ActionButton from "./FormUI/ActionButton";

const Refs = (props) => {
  const [formValues, setFormValues] = useState(null);
  const back = "<<< Back";

  const RefsSchema = Yup.object().shape({
    referees: Yup.object().shape({
      ref1Name: Yup.string()
        .min(5, "Referees name has to be at least 5 characters!")
        .max(50, "Referees name is too long!")
        .required("Referees name is required!"),
      ref1Occupation: Yup.string()
        .min(5, "Occupation has to be at least 5 characters!")
        .max(50, "Occupation is too long!")
        .required("Occupation is required!"),
      ref1Address: Yup.string()
        .min(10, "Address has to be at least 10 characters!")
        .max(80, "Address is too long!")
        .required("Address is required!"),
      ref1Email: Yup.string()
        .email("Email is invalid")
        .required("Email is required!"),
      ref1Relationship: Yup.string()
        .min(3, "Relationship has to be at least 3 characters!")
        .max(30, "Relationship is too long!")
        .required("Relationship is required!"),
      ref1Mobile: Yup.string()
        .min(7, "Phone/Mobile # is has to be at least 7 digits!")
        .max(7, "Phone/Mobile # is too long!")
        .required("Phone/Mobile # is required!"),
      ref2Name: Yup.string()
        .min(5, "Referees name has to be at least 5 characters!")
        .max(50, "Referees name is too long!")
        .required("Referees name is required!"),
      ref2Occupation: Yup.string()
        .min(5, "Occupation has to be at least 5 characters!")
        .max(50, "Occupation is too long!")
        .required("Occupation is required!"),
      ref2Address: Yup.string()
        .min(10, "Address has to be at least 10 characters!")
        .max(80, "Address is too long!")
        .required("Address is required!"),
      ref2Email: Yup.string()
        .email("Email is invalid")
        .required("Email is required!"),
      ref2Relationship: Yup.string()
        .min(3, "Relationship has to be at least 3 characters!")
        .max(30, "Relationship is too long!")
        .required("Relationship is required!"),
      ref2Mobile: Yup.string()
        .min(7, "Phone/Mobile # has to be at least 7 digits!")
        .max(7, "Phone/Mobile # is too long!")
        .required("Phone/Mobile # is required!"),
    }),
  });

  const handleSubmit = (values) => {
    props.next(values);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [props.data]);

  return (
    <section className={styles.main}>
      <Paper elevation={0} className={styles.container}>
        <Formik
          initialValues={formValues || props.data}
          onSubmit={handleSubmit}
          validationSchema={RefsSchema}
          validateOnMount
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <h2>Refs</h2>

              {/* Referee 1 */}
              <Paper
                elevation={0}
                variant="outlined"
                style={{
                  marginBottom: "20px",
                  padding: "1rem",
                  border: "1px solid #002a48",
                }}
              >
                {/* details */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3>Referee 1</h3>
                    <p>
                      Must be relative, if your are married, has to be your
                      spouse.
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Textfield label="Name *" name="referees.ref1Name" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Occupation *"
                      name="referees.ref1Occupation"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Textfield label="Address *" name="referees.ref1Address" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield label="Email *" name="referees.ref1Email" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Relationship *"
                      name="referees.ref1Relationship"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Phone/Mobile # *"
                      name="referees.ref1Mobile"
                    />
                  </Grid>
                </Grid>
              </Paper>

              {/* Referee 2 */}
              <Paper
                elevation={0}
                variant="outlined"
                style={{
                  marginBottom: "20px",
                  padding: "1rem",
                  border: "1px solid #002a48",
                }}
              >
                {/* details */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3>Referee 2</h3>
                    <p>Must be a workmate (manager/supervisor)</p>
                  </Grid>

                  {/* name */}
                  <Grid item xs={12} sm={6}>
                    <Textfield label="Name *" name="referees.ref2Name" />
                  </Grid>

                  {/* occupation */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Occupation *"
                      name="referees.ref2Occupation"
                    />
                  </Grid>

                  {/* address */}
                  <Grid item xs={12} sm={6}>
                    <Textfield label="Address *" name="referees.ref2Address" />
                  </Grid>

                  {/* email */}
                  <Grid item xs={12} sm={6}>
                    <Textfield label="Email *" name="referees.ref2Email" />
                  </Grid>

                  {/* relationship */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Relationship *"
                      name="referees.ref2Relationship"
                    />
                  </Grid>

                  {/* mobile */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Phone/Mobile # *"
                      name="referees.ref2Mobile"
                    />
                  </Grid>
                </Grid>
              </Paper>

              {/* action buttons */}
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <ActionButton
                    onClick={() => {
                      props.prev(values);
                    }}
                  >
                    {back}
                  </ActionButton>
                  <SubmitButton>Next{" >>>"}</SubmitButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </section>
  );
};

export default Refs;
