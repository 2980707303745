import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { format } from "date-fns";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Textfield from "../FormUI/Textfield";

const DECLINE_REQS_NTFCTN =
  "The application has been declined, assessment saved, and the client notified via email!";

const EMAIL_SUBJECT = "Unsecured Personal Loan Application Update";

const Decline = (props) => {
  const {
    open,
    handleClose,
    sendMsg,
    firstName,
    loanAmount,
    dateApplied,
    appStatus,
    setAppStatus,
    updateDeclineStatus,
    saveDeclineAssessment,
  } = props;
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [isReqs, setIsReqs] = useState(true);
  const [isFinance, setIsFinance] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [otherDeclineReason, setOtherDeclineReason] = useState("");

  const DECLINE_FINANCE =
    "Bula " +
    firstName +
    ",<br />" +
    "<br />" +
    "<h3>" +
    "Decision" +
    "</h3>" +
    "Your loan application for $" +
    loanAmount +
    " " +
    "sent in on " +
    format(dateApplied.toDate(), "EEE dd MMM yyyy hh:mm a") +
    " has been <strong>declined</strong>." +
    "<br />" +
    "We also tried with lower loan amounts and these were not successful.<br/>" +
    "<br />" +
    "<h3>Reason - Section 47 of the Employment Relations Promulgation 2007</h3>" +
    firstName +
    ", under this law, your deductions cannot be more than 50% of your wages. If the new loan repayment is added, your deductions will pass this legal limit." +
    "<br/><br/>" +
    "Thank you for giving us the opportunity. We wish you all the best.";

  const validationSchema = Yup.object({
    bankStatement: Yup.boolean(),
    payslips: Yup.boolean(),
    confirmationLetter: Yup.boolean(),
    photoIDs: Yup.boolean(),
    tin: Yup.boolean(),
    otherStatements: Yup.boolean(),
  }).test(
    "at-least-one",
    "Please select at least one checkbox",
    function (values) {
      return (
        values.bankStatement ||
        values.payslips ||
        values.confirmationLetter ||
        values.photoIDs ||
        values.tin ||
        values.otherStatements
      );
    }
  );

  const handleDeclineChange = (e) => {
    if (e.target.value == "reqs") {
      setIsReqs(true);
      setIsFinance(false);
      setIsOther(false);
    }
    if (e.target.value == "finance") {
      setIsFinance(true);
      setIsOther(false);
      setIsReqs(false);
    }
    if (e.target.value == "other") {
      setIsOther(true);
      setIsFinance(false);
      setIsReqs(false);
    }
  };

  const notifyUser = (notification) => {
    toast.info(notification, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const handleDecline = (values) => {
    let declineReason = "";

    if (values.reason === "reqs") {
      declineReason = "requirements";
      let message =
        "Bula " +
        firstName +
        ",<br /><br />" +
        "Your loan application for $" +
        loanAmount +
        " sent in on " +
        format(dateApplied.toDate(), "EEE dd MMM yyyy hh:mm a") +
        " has been <strong>declined</strong>." +
        "<h3>Reason</h3>" +
        "It was declined due to the following requirements <strong>NOT</strong> being provided:<br /><ul>";

      if (values.bankStatement) {
        message =
          message +
          "<li>A recent bank statement covering the past 3 months</li>";
      }

      if (values.payslips) {
        message = message + "<li>Two latest payslips</li>";
      }
      if (values.confirmationLetter) {
        message =
          message +
          "<li>Employment confirmation letter from employer dated in the past 30 days</li>";
      }
      if (values.photoIDs) {
        message = message + "<li>Two valid photo IDs</li>";
      }
      if (values.tin) {
        message = message + "<li>Valid TIN Card or letter</li>";
      }
      if (values.otherStatements) {
        message =
          message +
          "<li>Other loan(s), hire purchase(s), or credit card statements covering the past 2 months</li>";
      }

      message =
        message +
        "</ul><br/>" +
        "<h3>Copy Application and Reapply</h3>" +
        "Your are most welcome to apply again providing <strong>ALL</strong> the requirements.<br/>" +
        "We have made it easier to <strong>REAPPLY</strong> by providing a <strong>COPY</strong> application " +
        "feature so you do not have to enter all details again but just provide what's required.<br/>" +
        "This can be found here https://loans.mfl.com.fj/ ";

      // send the email
      sendMsg(message);

      // show pop up notification saying email has been sent and assessment has been saved
    }
    if (values.reason === "finance") {
      declineReason = "finance";
      sendMsg(DECLINE_FINANCE);
    }
    if (values.reason === "other") {
      declineReason = "other";

      let declineOtherReason =
        "Bula " +
        firstName +
        ",<br /><br />" +
        "Your loan application for $" +
        loanAmount +
        " sent in on " +
        format(dateApplied.toDate(), "EEE dd MMM yyyy hh:mm a") +
        " has been declined. <br />" +
        otherDeclineReason;

      // console.log("declineOtherReason", declineOtherReason);
      sendMsg(declineOtherReason);
    }
    // update the status of the application and also save the decline reason
    updateDeclineStatus(declineReason, otherDeclineReason);

    // save a assessment
    saveDeclineAssessment(declineReason);

    handleClose();
  };

  const declineOptions = [
    { key: "Requirements", value: "requirements" },
    { key: "Finance", value: "finance" },
  ];

  const initialValues = {
    bankStatement: false,
    payslips: false,
    confirmationLetter: false,
    photoIDs: false,
    tin: false,
    otherStatements: false,
    // decline: declineOptions.Requirements,
    reason: "reqs",
  };

  return (
    // <Grid>
    <Grid item xs={12}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Reason for Declining Application</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleDecline}
          >
            {({ values, errors, touched }) => (
              <Form>
                {/* options for decline ================================================== */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label style={{ padding: "0" }}>
                    <Field
                      name="reason"
                      type="radio"
                      value="reqs"
                      checked={values.reason === "reqs"}
                      onClick={handleDeclineChange}
                    />{" "}
                    Requirements
                  </label>

                  <label style={{ padding: "0" }}>
                    <Field
                      name="reason"
                      type="radio"
                      value="finance"
                      checked={values.reason === "finance"}
                      onClick={handleDeclineChange}
                    />{" "}
                    Finance
                  </label>
                  <label style={{ padding: "0" }}>
                    <Field
                      name="reason"
                      type="radio"
                      value="other"
                      checked={values.reason === "other"}
                      onClick={handleDeclineChange}
                    />{" "}
                    Other
                  </label>
                </div>
                <Divider style={{ marginBottom: ".5rem" }} />
                {/* requirements and finance options ================================================== */}
                {isReqs && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: ".2rem",
                    }}
                  >
                    {/* bankStatement */}
                    <label style={{ padding: "0" }}>
                      <Field name="bankStatement" type="checkbox" /> Latest bank
                      statement covering the past 3 months
                    </label>
                    {/* payslips */}
                    <label style={{ padding: "0" }}>
                      <Field name="payslips" type="checkbox" /> Two latest
                      payslips
                    </label>
                    {/* confirmationLetter */}
                    <label style={{ padding: "0" }}>
                      <Field name="confirmationLetter" type="checkbox" />{" "}
                      Employment confirmation letter from employer dated in the
                      past 30 days
                    </label>
                    {/* photoIDs */}
                    <label style={{ padding: "0" }}>
                      <Field name="photoIDs" type="checkbox" /> Two valid photo
                      IDs
                    </label>
                    {/* tin */}
                    <label style={{ padding: "0" }}>
                      <Field name="tin" type="checkbox" /> Valid TIN Card or
                      letter
                    </label>{" "}
                    {/* otherStatements */}
                    <label style={{ padding: "0" }}>
                      <Field name="otherStatements" type="checkbox" /> Other
                      most recent loan, hire purchase statements
                    </label>
                  </div>
                )}

                {isFinance && (
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: ".2rem",
                    }}
                  >
                    The applicant did not satisfy our financial conditions.
                  </p>
                )}

                {isOther && (
                  <TextField
                    label="Other Decline Reason"
                    fullWidth
                    multiline
                    rows={5}
                    style={{ marginTop: ".5rem" }}
                    value={otherDeclineReason}
                    onChange={(e) => setOtherDeclineReason(e.target.value)}
                    placeholder="Provide the other reason for declining the application which will be emailed to the customer. You can start with It was declined due to ..."
                  />
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    columnGap: ".2em",
                    marginTop: ".5em",
                  }}
                >
                  {errors.bankStatement ||
                  errors.payslips ||
                  errors.confirmationLetter ||
                  errors.photoIDs ||
                  errors.tin ||
                  errors.otherStatements ? (
                    <div style={{ color: "red" }}>
                      {errors.bankStatement ||
                        errors.payslips ||
                        errors.confirmationLetter ||
                        errors.photoIDs ||
                        errors.tin ||
                        errors.otherStatements}
                    </div>
                  ) : null}
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      minWidth: "100px",
                      backgroundColor: "#002a48",
                      textTransform: "capitalize",
                      borderRadius: "25px",
                    }}
                  >
                    Decline
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          {/* </Box> */}
        </DialogContent>
      </Dialog>
    </Grid>
    // </Grid>
  );
};

export default Decline;
