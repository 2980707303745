import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Tooltip } from "@mui/material";

const FileSelector = (props) => {
  const { files, setFiles } = props;
  const MAX_SIZE = 1048576;
  const MAX_FILES = 5;

  const onDrop = (acceptedFiles) => {
    if (files.length + acceptedFiles.length <= MAX_FILES) {
      const filteredFiles = acceptedFiles.filter(
        (file) => !files.find((f) => f.name === file.name)
      );
      setFiles([...files, ...filteredFiles]);
    }

    // let filteredFiles2 = filteredFiles.filter((file) => file.size <= MAX_SIZE);
  };

  const deleteFile = (fileIndex) => {
    const updatedFiles = [...files];
    updatedFiles.splice(fileIndex, 1);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES,
    // maxSize: MAX_SIZE,
    // maxSize: 1048576,
  });

  return (
    <div
      style={{
        // padding: "0.5rem",
        marginTop: "10px",
        width: "100%",
      }}
    >
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
        style={{
          border: "1px dashed #002a48",
          borderRadius: "5px",
          padding: ".5rem",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <p>Drag and drop files here, or click to select files</p>
      </div>
      {files && files.length > 0 && (
        <div className="file-list">
          <h4>Selected Files:</h4>
          <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
            {files.map((file, i) => (
              <li key={uuidv4()} style={{}}>
                <span>
                  {i + 1}
                  {". "} {file.name}
                </span>{" "}
                <span>{Math.round(file.size / 1024 / 1024)} MB</span>
                <span>
                  <Tooltip title="Remove file" arrow>
                    <DeleteForeverIcon
                      onClick={() => deleteFile(i)}
                      color="primary"
                    />
                  </Tooltip>
                </span>
                {/* <button onClick={() => onDelete(index)}>Delete</button> */}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileSelector;
