import React, { useEffect } from "react";

import "./Search.css";

const Filter = (props) => {
  // const { currentFilter, changeFilter, stageCount, isLoading } = props;
  const { currentFilter, changeFilter, isLoading } = props;
  const statuses = [
    "All",
    "Submitted",
    "Assessing",
    "Recommended",
    "Approved",
    "Escalated",
    "Declined",
  ];

  const handleFilter = (newFilter) => {
    changeFilter(newFilter);
  };

  return (
    <div>
      {/* filters */}
      <div className="status-filter">
        {statuses.map((status, i) => (
          <button
            key={i}
            onClick={() => handleFilter(status)}
            className={currentFilter === status ? "active" : ""}
            disabled={isLoading}
          >
            {status}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Filter;
