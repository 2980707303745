import { Button } from "@mui/material";

const ActionButton = ({ children, ...otherProps }) => {
  const configButton = {
    ...otherProps,
    variant: "contained",
  };

  return (
    <Button
      sx={{
        minWidth: "100px",
        textTransform: "capitalize",
        backgroundColor: "#002a48",
        borderRadius: "25px",
        size: "small",
      }}
      {...configButton}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
