import { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

//firebase imports
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";

export const useLogOut = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const logOut = async () => {
    try {
      navigate("/");

      await signOut(auth);
      dispatch({ type: "LOGOUT" });

      // set the state
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      // set the state
      if (!isCancelled) {
        setIsPending(false);
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { error, logOut, isPending };
};
