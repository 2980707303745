import { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
// import { useUpload } from "../hooks/useUpload";
// import { Stepper, Step, StepLabel } from "@mui/material";

//firebase imports
import { db, storage } from "../firebase/config";
import {
  collection,
  addDoc,
  setDoc,
  updateDoc,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { useAuthContext } from "../hooks/useAuthContext";

import LoanDetails from "./LoanDetails";
import AppDetails from "./AppDetails";
import LoanFiles from "./LoanFiles";
import Submission from "./Submission";
import Refs from "./Refs";
// import { format } from "date-fns";
// import LoanStepper from "../components/loanStepper/LoanStepper";
// import { LightbulbCircleOutlined } from "@mui/icons-material";

const FIREBASE_DOCUMENT = "unsecuredLoanApplications";

const FormStepper = () => {
  const [uplApp, setUplApp] = useState();
  const [urls, setURLs] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  // const { error, uploading, progress, downloadURL, Upload } = useUpload();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const uplSteps = [
    "1 - Customer Details",
    "2 - Referees",
    "3 - Loan Details & Expenses",
    "4 - Loan Files",
    "5 - Submission",
  ];

  // set the urls for each file
  const [sig, setSig] = useState([]);
  const [pid, setPID] = useState([]);
  const [empCon, setEmpCon] = useState([]);
  const [tin, setTin] = useState([]);
  const [ps, setPs] = useState([]);
  const [bs, setBs] = useState([]);
  const [os, setOs] = useState([]);

  const [data, setData] = useState({
    // Personal Details
    mflCustomerStatus: "New",
    firstName: "",
    otherNames: "",
    lastName: "",
    postalAddress: "",
    residentialAddress1: "",
    durationOfStay1: "",
    residentialAddress2: "",
    durationOfStay2: "",
    email: user.email,
    mobile: "",
    dateOfBirth: new Date().toISOString().split("T")[0],
    maritalStatus: "single",
    dependants: "",
    photoIDs: [],
    signature: [],
    // Employment Details
    fnpf: "",
    tin: "",
    employer: "",
    occupation: "",
    annualSalary: "",
    payFrequency: "fortnightly",
    employerAddress: "",
    durationOfEmployment1: "",
    previousEmployer: "",
    durationOfEmployment2: "",
    employmentConfirmation: [],
    tinLetterCard: [],
    payslips: [],
    additionalIncome: "",
    // Bank Details
    bank: "bsp",
    branch: "",
    accountName: "",
    accountNumber: "",
    bankStatement: [],
    // bankStatementURL: "",
    // Referees
    referees: {
      ref1Name: "",
      ref1Occupation: "",
      ref1Address: "",
      ref1Email: "",
      ref1Relationship: "",
      ref1Mobile: "",
      ref2Name: "",
      ref2Occupation: "",
      ref2Address: "",
      ref2Email: "",
      ref2Relationship: "",
      ref2Mobile: "",
    },
    // Personal finance
    loanAmount: "",
    loanTerm: "36",
    loanPurpose: "",
    rent: "",
    electricity: "",
    water: "",
    mobileSpend: "",
    groceries: "",
    housekeeping: "",
    schoolFees: "",
    travel: "",
    subscriptions: "",
    entertainment: "",
    insurance: "",
    homeLoans: "",
    otherLoans: "",
    hirePurchase: "",
    otherExpenses: "",
    creditCards: "",
    otherStatements: [],
    //
    bankruptcy: false,
    debtRecovery: false,
    creditConsent: false,
    infoDeclaration: false,
    // app data
    assessor: null,
    status: null,
    appStatus: null,
    uid: user.uid,
    // system
    createdAt: "",
    appliedAt: "",
    updatedAt: "",
    recommendationAt: "",
    decisionAt: "",
    dateCreated: "",
    dateApplied: "",
    dateAssessed: "",
    dateDeclined: "",
    dateApproved: "",
  });

  const [currentStep, setCurrentStep] = useState(0);

  // get the application for the id provided
  // this is the case for the
  const getApp = async () => {
    if (id) {
      try {
        const docRef = doc(db, "unsecuredLoanApplications", id);
        let docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // remove all data related to the files

          // const updatedEmpApp = { ...empApp, sig: [] };
          const tmp = {
            ...docSnap.data(),
            signature: [],
            tinLetterCard: [],
            photoIDs: [],
            payslips: [],
            otherStatements: [],
            employmentConfirmation: [],
            bankStatement: [],
            creditConsent: false,
            infoDeclaration: false,
            dateApplied: "",
            dateAssessed: "",
            dateDeclined: "",
            dateApproved: "",
            assessor: null,
            dateRecommended: "",
            recommendation: "",
            recommend_uid: "",
            finaliseComments: "",
            dateFinaliseEscalated: "",
            finaliseEscalate_uid: "",
            dateFinaliseDeclined: "",
            finaliseDeclineEmailMsg: "",
            finaliseDecline_uid: "",
            dateEscalatedToApproved: "",
            escalatedTo: "",
            escalatedToComments: "",
            escalatedToApprove_uid: "",
            dateFinaliseDeclined: "",
          };

          // set the data
          setUplApp(tmp);
          setData(tmp);
        }
      } catch (error) {
        console.error(error.message);
        setError("Error getting application: " + error.message);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getApp();
    }
  }, [id]);

  const saveApplication = async (formData) => {
    setIsLoading(true);
    try {
      // set fields for saving
      formData.appliedAt = serverTimestamp();
      formData.createdAt = serverTimestamp();
      formData.dateApplied = serverTimestamp();
      formData.dateCreated = serverTimestamp();
      formData.status = "Submitted";
      formData.appStatus = "Open";

      // set up the files
      let signature = formData.signature;
      let photoIDs = formData.photoIDs;
      let employmentConfirmation = formData.employmentConfirmation;
      let tinLetterCard = formData.tinLetterCard;
      let payslips = formData.payslips;
      let bankStatement = formData.bankStatement;
      let otherStatements = formData.otherStatements;

      // reset file refernces from formData
      data.signature = [];
      data.photoIDs = [];
      data.employmentConfirmation = [];
      data.tinLetterCard = [];
      data.payslips = [];
      data.bankStatement = [];
      data.otherStatements = [];

      console.log("data before add", data);

      // add the document
      const res = await addDoc(collection(db, "unsecuredLoanApplications"), {
        ...data,
      });

      console.log("Doc added -->", res.id);
      console.log("photo ids -->", photoIDs);

      await UploadFiles(res.id, signature, "sig");
      await UploadFiles(res.id, photoIDs, "pid");
      await UploadFiles(res.id, employmentConfirmation, "empCon");
      await UploadFiles(res.id, tinLetterCard, "tin");
      await UploadFiles(res.id, payslips, "ps");
      await UploadFiles(res.id, bankStatement, "bs");
      await UploadFiles(res.id, otherStatements, "os");

      // update the document with the download urls
      const docRef = doc(db, "unsecuredLoanApplications", res.id);

      console.log("sig", sig);
      console.log("ps", ps);

      // Update the timestamp field with the value from the server
      const updateTimestamp = await updateDoc(docRef, {
        signature: sig,
        photoIDs: pid,
        employmentConfirmation: empCon,
        tinLetterCard: tin,
        payslips: ps,
        bankStatement: bs,
        otherStatements: os,
      });

      // navigate("/upl-applications");
    } catch (err) {
      console.error("Error in Stepper", err.message);
      setError("Error saving application: " + err.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const UploadFiles = (appID, files, fileName) => {
    const storage = getStorage();
    const promises = [];
    let dURLs = [];

    files.map((file, i) => {
      let storageRef = "";
      try {
        storageRef = ref(storage, `reqs/upl/${appID}/${file.name}`);
      } catch (err) {
        console.error("file.name", err.message);
      }

      // Upload file
      const uploadTask = uploadBytesResumable(storageRef, file);
      // promises.push(uploadTask);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (err) => {
          setError("Upload Error: " + err.message);
          console.error("Upload error", err.message);
        },
        async () => {
          try {
            // Upload completed successfully, now we can get the download URL
            await getDownloadURL(uploadTask.snapshot.ref).then(
              (downloadURL) => {
                // console.log("File available at", i, downloadURL);
                setURLs((prevState) => [...prevState, downloadURL]);
                dURLs.push(downloadURL);

                switch (fileName) {
                  case "sig":
                    setSig([...sig, downloadURL]);
                    break;
                  case "pid":
                    setPID([...pid, downloadURL]);
                    break;
                  case "empCon":
                    setEmpCon([...empCon, downloadURL]);
                    break;
                  case "tin":
                    setTin([...tin, downloadURL]);
                    break;
                  case "ps":
                    setPs([...ps, downloadURL]);
                    break;
                  case "bs":
                    setBs([...bs, downloadURL]);
                    break;
                  case "os":
                    setOs([...os, downloadURL]);
                    break;
                }
              }
            );
          } catch (err) {
            console.error("Get downloan url error:", err);
            setError("Get downloan url error: " + err.message);
          }
        }
      );
    });

    // Promise.all(promises)
    //   .then(console.log("All files uploaded!"))
    //   .catch((err) => console.log("Üpload error", err));
  };

  const updateApplication = async (formData) => {
    try {
      // set the values
      formData.status = "Submitted";
      formData.uid = user.uid;
      formData.updatedAt = serverTimestamp();

      //deal with the images
      // UploadFile(formData);
      await UploadFiles(formData.signature);
      setData((prev) => ({ ...prev, signature: urls }));
      setURLs([]);

      await UploadFiles(formData.photoIDs);
      setData((prev) => ({ ...prev, photoIDs: urls }));
      setURLs([]);

      await UploadFiles(formData.employmentConfirmation);
      setData((prev) => ({ ...prev, employmentConfirmation: urls }));
      setURLs([]);

      await UploadFiles(formData.tinLetterCard);
      setData((prev) => ({ ...prev, tinLetterCard: urls }));
      setURLs([]);

      await UploadFiles(formData.bankStatement);
      setData((prev) => ({ ...prev, bankStatement: urls }));
      setURLs([]);

      await UploadFiles(formData.otherStatements);
      setData((prev) => ({ ...prev, otherStatements: urls }));
      setURLs([]);

      console.log("data after update and deletion", formData);

      // update the document
      const res = await setDoc(doc(db, FIREBASE_DOCUMENT, id), formData);
      console.log("Data has been updated !!!", res);
    } catch (err) {
      console.error("Error in Stepper: Update", err.message);
      setError("Update loan application error: " + err.message);
    }
  };

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));

    if (final) {
      // edit mode
      if (id) {
        updateApplication(newData);
      } else {
        // add mode
        saveApplication(newData);
      }
      return;
    }

    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <AppDetails prev={handlePrevStep} next={handleNextStep} data={data} />,
    <Refs prev={handlePrevStep} next={handleNextStep} data={data} />,
    <LoanDetails prev={handlePrevStep} next={handleNextStep} data={data} />,
    <LoanFiles
      prev={handlePrevStep}
      next={handleNextStep}
      data={data}
      isLoading={isLoading}
    />,
    // <>
    //   {id ? (
    //     <LoanFiles
    //       prev={handlePrevStep}
    //       next={handleNextStep}
    //       data={data}
    //       editMode="true"
    //       isLoading={isLoading}
    //     />
    //   ) : (
    //     <LoanFiles
    //       prev={handlePrevStep}
    //       next={handleNextStep}
    //       data={data}
    //       isLoading={isLoading}
    //     />
    //   )}
    // </>,

    // ####
    // <>{id ? null : <Submission prev={handlePrevStep} data={data} />}</>,
    <>
      <Submission prev={handlePrevStep} data={data} />
    </>,
  ];

  return (
    <div className="App">
      {/* <LoanStepper steps={uplSteps} activeStep={currentStep} /> */}
      {steps[currentStep]}
      {error && (
        <p
          style={{
            backgroundColor: "lightcoral",
            padding: "1rem",
            border: "1px solid lightcoral",
            borderRadius: "5px",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default FormStepper;
