import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import MenuIcon from "@mui/icons-material/Menu";
// import MenuIcon from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import logo from "../../assets/mfl-logo.png";

import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogOut } from "../../hooks/useLogOut";
import { useNavigate } from "react-router-dom";

// firebase imports
import { db, auth } from "../../firebase/config";
import { doc, getDoc } from "firebase/firestore";

import useGetDoc from "../../hooks/useGetDoc";

const drawerWidth = 240;
// const navItems = ["Home", "About", "Login"];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const { logOut } = useLogOut();
  const { user, authIsReady } = useAuthContext();
  const [hasRoles, setHasRoles] = useState(false);
  const [error, setError] = useState(null);
  const [isApprover, setIsApprover] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // show on mobile
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        color: "#fff",
        backgroundColor: "#002a48",
      }}
    >
      <img
        src={logo}
        alt="Merchant Finance logo"
        height="50"
        weight="50"
        style={{ marginTop: "10px" }}
      />
      <Typography
        variant="h6"
        sx={{ my: 2, color: "#fff", marginLeft: "10px" }}
      >
        Loans Portal
      </Typography>
      <Divider sx={{ borderColor: "#fff" }} />
      <List>
        <ListItem key="home" disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText primary="Home" onClick={() => navigate("/")} />
          </ListItemButton>
        </ListItem>

        {user && hasRoles && (
          <ListItem key="dashboard" disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/dashboard")}
            >
              <ListItemText
                primary="Dashboard"
                onClick={() => navigate("/dashboard")}
              />
            </ListItemButton>
          </ListItem>
        )}

        {user && hasRoles && (
          <ListItem key="search" disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/search")}
            >
              <ListItemText
                primary="Search"
                onClick={() => navigate("/search")}
              />
            </ListItemButton>
          </ListItem>
        )}

        {user && isApprover && (
          <ListItem key="roles" disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/users")}
            >
              <ListItemText
                primary="Roles"
                onClick={() => navigate("/users")}
              />
            </ListItemButton>
          </ListItem>
        )}

        {user && (
          <ListItem key="signout" disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={(e) => handleSignOut(e)}
            >
              <ListItemText
                primary="Sign Out"
                onClick={(e) => handleSignOut(e)}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleSignOut = (e) => {
    e.preventDefault();
    logOut();
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          if (docSnap.data().roles.length > 0) {
            setHasRoles(true);

            // check if user is approver
            if (docSnap.data().roles.includes("approver")) {
              setIsApprover(true);
              // } else {
              //   setIsApprover(false);
            }
          }
        }
      } catch (error) {
        setError(error.code + ": " + error.message);
      }
    };

    if (user) getRoles();
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ color: "#fff", backgroundColor: "#002a48" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt="Merchant Finance logo"
            height="50"
            weight="50"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          />

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block", marginLeft: "10px" },
            }}
          >
            Loans Portal
          </Typography>

          {/* mobile */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "block", sm: "none" },
              textAlign: "center",
            }}
          >
            Loans Portal
          </Typography>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {user && (
              <Button
                key="home"
                sx={{ color: "#fff" }}
                onClick={() => navigate("/")}
              >
                Home
              </Button>
            )}

            {user && hasRoles && (
              <Button
                key="dashboard"
                sx={{ color: "#fff" }}
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </Button>
            )}

            {user && hasRoles && (
              // {user && (
              <Button
                key="search"
                sx={{ color: "#fff" }}
                onClick={() => navigate("/search")}
              >
                Search
              </Button>
            )}

            {/* set user roles */}
            {user && isApprover && (
              // {user && (
              <Button
                key="roles"
                sx={{ color: "#fff" }}
                onClick={() => navigate("/users")}
              >
                Roles
              </Button>
            )}

            {user && (
              <Button
                key="logout"
                onClick={handleSignOut}
                sx={{ color: "#fff" }}
              >
                Sign Out
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
