import { useState } from "react";

import { Button, Grid, Paper, Typography, InputAdornment } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Textfield from "../components/FormUI/Textfield";
import Selectfield from "../components/FormUI/Selectfield";
import Textarea from "./FormUI/Textarea";
import CheckboxWrapper from "./FormUI/CheckboxWrapper";
import ActionButton from "./FormUI/ActionButton";
import SubmitButton from "./FormUI/SubmitButton";
import Highlight from "./highlight/Highlight";

import styles from "./Refs.module.css";

const EXPENSES_MSG =
  "Please ensure that you provide accurate information for each expense item listed below.";
const OTHER_PYMTS_MSG =
  "If you have deductions to any of the following items below, you will need to provide the " +
  "most recent statements covering the past two (2) months in the next section Loan Files";

const LoanDetails = (props) => {
  const [formValues, setFormValues] = useState(null);
  const back = "<<< Back";

  const validationSchema = Yup.object().shape({
    loanAmount: Yup.number()
      .min(500, "Loan amount must be at least $500")
      .max(15000, "Loan amount must be <= to $15,000")
      .required("Loan amount is required"),
    loanTerm: Yup.string()
      // .min(3, "Loan term is too short!")
      // .max(5, "Loan term is too long!")
      .required("Loan term is required!"),
    loanPurpose: Yup.string()
      .min(10, "Loan purpose is too short!")
      .max(100, "Loan purpose is too long!")
      .required("Loan purpose is required!"),
    rent: Yup.string().required("Rent is required!"),
    electricity: Yup.string().required("Electricity is required!"),
    water: Yup.string().required("Water is required!"),
    mobileSpend: Yup.string().required("Mobile spend is required!"),
    groceries: Yup.string().required("Groceries spend is required!"),
    schoolFees: Yup.string().required("School fees spend is required!"),
    travel: Yup.string().required("Travel is required!"),
    subscriptions: Yup.string().required("Subscriptions is required!"),
    entertainment: Yup.string().required("Entertainment is required!"),
    insurance: Yup.string().required(
      "Insurance is required! If this does not apply to you, enter 0"
    ),
    homeLoans: Yup.string().required("Home loans is required!"),
    otherLoans: Yup.string().required("Other loans is required!"),
    hirePurchase: Yup.string().required("Hire purchase is required!"),
    otherExpenses: Yup.string().required("Other expenses is required!"),
    creditCards: Yup.string().required("Credit cards is required!"),
  });

  const loanTermOptions = {
    // "": "Select a loan term",
    6: "6 months",
    12: "1 Year",
    18: "1.5 Years",
    24: "2 Years",
    30: "2.5 Years",
    36: "3 Years",
    42: "3.5 Years",
    48: "4 Years",
  };

  const handleSubmit = (values) => {
    props.next(values); 
  };

  return (
    <section className={styles.main}>
      <Paper elevation={0} className={styles.container}>
        <Formik
          initialValues={formValues || props.data}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <Paper
                elevation={0}
                variant="outlined"
                style={{
                  marginBottom: "20px",
                  padding: "1rem",
                  border: "1px solid #002a48",
                }}
              >
                {/* start of loan details */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h2>Loan Details</h2>
                  </Grid>

                  {/* loan amount */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      name="loanAmount"
                      label="Loan Amount"
                      type="number"
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* loan term */}
                  <Grid item xs={12} sm={6}>
                    <Selectfield
                      label="Loan Term"
                      name="loanTerm"
                      options={loanTermOptions}
                    />
                  </Grid>

                  {/* loan purpose */}
                  <Grid item xs={12}>
                    <Textfield
                      label="Purpose of the Loan"
                      name="loanPurpose"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                elevation={0}
                variant="outlined"
                style={{
                  marginBottom: "20px",
                  padding: "1rem",
                  border: "1px solid #002a48",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>

                    <h2>Monthly Expenses</h2>
                    <Grid item xs={12} sm={12}>
                      <Highlight text={EXPENSES_MSG} />
                    </Grid>
                  </Grid>

                  {/* rent */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Rent"
                      name="rent"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* electricity */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Electricity"
                      name="electricity"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* water */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Water"
                      name="water"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* mobile spend */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Mobile Data & Calls"
                      name="mobileSpend"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                      style={{}}
                    />
                  </Grid>

                  {/* groceries */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Groceries / House Maintenance"
                      name="groceries"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* school fees */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="School Fees"
                      name="schoolFees"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* travel */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Travel fuel/bus/taxi"
                      name="travel"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* subscriptions */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Subscriptions eg. SKY, Internet, Netflix, etc"
                      name="subscriptions"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* entertainment */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Entertainment"
                      name="entertainment"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* highlight */}
                  <Grid item xs={12} sm={12}>
                    <Highlight text={OTHER_PYMTS_MSG} />
                  </Grid>

                  {/* insurance */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Total Monthly Insurance eg. Life, medical"
                      name="insurance"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* home loans */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Total Monthly Home Loans"
                      name="homeLoans"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* other loans */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Total Monthly Other Loan Payments"
                      name="otherLoans"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* hire purchase */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Total Monthly Hire Purchase Payments"
                      name="hirePurchase"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* other expenses */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Other Expenses"
                      name="otherExpenses"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>

                  {/* credit cards */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      label="Credit Cards"
                      name="creditCards"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        maxLength: 5,
                      }}
                    />
                  </Grid>
                </Grid>

                {/* end of container */}
              </Paper>

              {/* action buttons */}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <ActionButton
                    onClick={() => {
                      props.prev(values);
                    }}
                  >
                    {back}
                  </ActionButton>

                  <SubmitButton>Next{" >>>"}</SubmitButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </section>
  );
};

export default LoanDetails;
