import { Grid, Paper } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import Loans from "../loans/Loans";
import Applications from "../upl/Applications";
import ApplicationList from "./ApplicationList";
import Error from "../../components/error/Error";

import { Link } from "react-router-dom";

// firebase imports
import { db } from "../../firebase/config";
import { doc, getDoc } from "firebase/firestore";

import useGetDoc from "../../hooks/useGetDoc";
// import useGetDocs from "../../hooks/useGetDocs";

const UPL_APPS = "unsecuredLoanApplications";

const Application = () => {
  const { user } = useAuthContext();
  const [isAssessor, setIsAssessor] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [hasRoles, setHasRoles] = useState(false);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [apps, setApps] = useState(null);

  // get a limited set of UPL application
  const { documents: uplApplications } = useCollection(
    UPL_APPS,
    null,
    null,
    20
  );

  const { documents: uplApps } = useCollection(UPL_APPS, [
    "uid",
    "==",
    user.uid,
  ]);

  // get the roles for the user if any
  const getRoles = async () => {
    try {
      setIsLoading(true);

      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.data()) {
        // check if roles do exist
        if (docSnap.data().roles) {
          if (docSnap.data().roles.length > 0) {
            // the user is part of MFL
            setHasRoles(true);

            if (docSnap.data().roles.includes("assessor")) {
              setIsAssessor(true);
            }
            if (docSnap.data().roles.includes("approver")) {
              setIsApprover(true);
            }
            if (docSnap.data().roles.includes("manager")) {
              setIsManager(true);
            }
          }

          setRoles(docSnap.data().roles);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    getRoles();

    // abort data request
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Paper
      sx={{
        padding: "1rem",
        // width: "95%",
        margin: "0 auto",
      }}
    >
      <Grid>
        <Grid item xs={12}>
          {isLoading && <div>Loading data...</div>}

          {roles && uplApplications && uplApps && (
            <>
              {hasRoles ? (
                // get into the backend to process loan applications
                <Applications
                  uplApps={uplApplications}
                  isAssessor={isAssessor}
                />
              ) : (
                <div>
                  {/* // for users to see their applications and progress */}
                  <Loans />
                  {/* {apps} */}
                  <ApplicationList uplApps={uplApps} isAssessor={isAssessor} />

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Apply for a loan now:</p>
                    <Link to={"/new-upl-application"}>
                      Unsecured Personal Loan
                    </Link>
                  </div>
                </div>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {error && <Error err={error} module="Application" />}
    </Paper>
  );
};

export default Application;
